declare global {
  interface Window {
    env: any;
  }
}

// change with your own variables
type EnvType = {
  REACT_APP_API_BASE_URL: string;
  REACT_APP_API_VERSION: string;
  REACT_APP_PROXY_DESTINATION: string;
  REACT_APP_IDP_SIGNIN_BASE_URL: string;
  REACT_APP_IDP_SIGNOUT_BASE_URL: string;
  REACT_APP_BATCH_EVALUATOR_URL: string;
};
export const env: EnvType = { ...process.env, ...window.env };

import { useMemo } from 'react';
import './App.css';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import Structure from './features/page-structures/structure/Structure';
import { useAuthentication } from './hooks/useAuthentication';
import { toUseProduct } from './model/RequiredClaims';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { selectIdentity, setIdentity } from './store/Identity';
import { Identity } from './model/Identity';
import { isAuthorised } from './services/Authorisation';
import Error from './features/page-structures/error/Error';

export const AUTHORISATION_ERROR_HEADER = 'Access denied (error 403)';
export const AUTHORISATION_ERROR_DETAIL = 'You must have a product licence with the Live status';

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const storeIdentity = (identity: Identity) => dispatch(setIdentity(identity));

  const identity = useAppSelector(selectIdentity);
  const redirectAfterAuthentication = () => navigate(`${location.pathname}${location.search}`);

  const { isAuthenticated, authenticate, signOut, signingOut } = useAuthentication(
    storeIdentity,
    redirectAfterAuthentication,
  );
  const { authorised } = useMemo(() => isAuthorised(identity, toUseProduct), [identity]);

  if (!isAuthenticated(identity) && !signingOut) {
    authenticate();
    return <p>Redirecting signin...</p>;
  } else if (signingOut) {
    return <p>Redirecting signout...</p>;
  }

  if (!authorised) return <Error header={AUTHORISATION_ERROR_HEADER} detailMessage={AUTHORISATION_ERROR_DETAIL} />;

  if (window.location.hash) {
    window.history.replaceState('', document.title, window.location.pathname);
  }

  return (
    <Routes>
      <Route path="*" element={<Structure identity={identity} signOut={signOut} />} />
    </Routes>
  );
}

export default App;

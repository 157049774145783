import { ButtonProps } from '@gbg/gbgcomponentlibrary_react/build/Atoms/Button/Button.types';
import { useCallback } from 'react';
import { Error } from '../model/Error';
import { toast, ToastPosition, ToastType } from '@gbg/gbgcomponentlibrary_react';

const isError = (arg: any): arg is Error[] => {
  return Array.isArray(arg) && arg.length > 0 && arg[0].problem !== undefined;
};

interface WithApiCallHandlingProps<R> {
  call: () => Promise<R | Error[]>;
  successMessage?: string;
  onSuccess: (response: R) => void;
  onFailure: (error: string) => void;
}

function withApiCallHandling<T extends ButtonProps, R>(WrappedButton: React.ComponentType<T>) {
  const Button = (props: T & WithApiCallHandlingProps<R>) => {
    const { call, successMessage, onSuccess, onFailure, ...rest } = props;
    const wrappedComponentsProps: T = rest as unknown as T;
    const toastAndNotifySuccess = useCallback(
      (successfulResponse: R) => {
        if (successMessage) {
          toast({
            title: '',
            content: successMessage,
            position: ToastPosition.Top,
            type: ToastType.Success,
            duration: 3000,
            dismissable: false,
          });
        }
        onSuccess(successfulResponse);
      },
      [successMessage, onSuccess],
    );

    const callWithHandling = useCallback(async () => {
      const response = await call();
      if (isError(response)) {
        onFailure(`${response.map((v, i) => `${v.problem}: ${v.action}`).join(', ')}`);
      } else {
        toastAndNotifySuccess(response);
      }
    }, [call, onFailure, toastAndNotifySuccess]);

    return <WrappedButton {...wrappedComponentsProps} onClick={callWithHandling} />;
  };

  return Button;
}

export default withApiCallHandling;

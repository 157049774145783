import { authorize, parseHash, signOff } from '../common/ping';
import { Identity } from '../model/Identity';
import jwtDecode from 'jwt-decode';
import { useState } from 'react';

interface Tokens {
  access: string;
  id: string;
}
interface Response {
  isAuthenticated: (identity: Identity) => boolean;
  authenticate: () => Identity | void;
  signOut: () => void;
  signingOut: boolean;
}

export const useAuthentication = (
  storeIdentity: (identity: Identity) => void,
  redirectOnSuccess: () => void,
): Response => {
  const [signingOut, setSigningOut] = useState(false);

  return {
    isAuthenticated: (identity: Identity) => isAuthenticated(identity),
    authenticate: () => {
      if (!signingOut) authenticate(storeIdentity, redirectOnSuccess);
    },
    signOut: () => signOut(storeIdentity, setSigningOut),
    signingOut,
  };
};

const isAuthenticated = (identity: Identity): boolean => {
  return identity.isAuthenticated && !hasExpired(identity);
};

const authenticate = (storeIdentity: (identity: Identity) => void, redirectOnSuccess: () => void): void => {
  const tokens = getTokensFromIdpResponse();
  if (!tokens) redirectToIdPSignIn();
  else {
    const identity = getIdentity(tokens);
    storeIdentity(identity);
    redirectOnSuccess();
  }
};

const signOut = (storeIdentity: (identity: Identity) => void, setSigningOut: (signingOut: boolean) => void): void => {
  setSigningOut(true);
  storeIdentity(signingOutIdentity);
  redirectToIdPSignOut();
};

const signingOutIdentity: Identity = {
  isAuthenticated: false,
  accessToken: '',
  licensedProducts: {},
  licensedFunctions: [],
  givenName: '',
  familyName: '',
  email: '',
  companyName: '',
};

const getTokensFromIdpResponse = (): Tokens | null => {
  const hashAttributes = parseHash();
  if (!hashAttributes?.access_token || !hashAttributes.id_token) return null;

  return { access: hashAttributes.access_token, id: hashAttributes.id_token };
};

const redirectToIdPSignIn = (): void => {
  authorize();
};

const redirectToIdPSignOut = (): void => {
  signOff();
};

const getIdentity = (tokens: Tokens): Identity => {
  const accessAttributes = jwtDecode(tokens.access) as any;
  return {
    isAuthenticated: true,
    accessToken: tokens.access,
    accessTokenExpiry: accessAttributes.exp,
    licensedProducts: accessAttributes.licenceStatus ?? {},
    licensedFunctions: accessAttributes.functions?.functionIDs ?? [],
    givenName: accessAttributes.user_firstname,
    familyName: accessAttributes.user_familyname,
    email: accessAttributes.user_email,
    companyName: accessAttributes.company_name,
  };
};

const hasExpired = (identity: Identity): boolean => {
  const currentTimeInMs = Date.now();
  if (!identity.accessTokenExpiry) return false;

  const expiryTimeInMs = identity.accessTokenExpiry * 1000;
  return expiryTimeInMs <= currentTimeInMs;
};

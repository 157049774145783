import React from 'react';

interface WithConditionalRenderingProps {
  visible: boolean;
}

function withConditionalRendering<T extends object>(WrappedComponent: React.ComponentType<T>) {
  const ConditionallyRendered = (props: T & WithConditionalRenderingProps) => {
    const { visible, ...rest } = props;
    const wrappedComponentsProps: T = rest as unknown as T;
    if (!props.visible) return null;

    return <WrappedComponent {...wrappedComponentsProps} />;
  };

  return ConditionallyRendered;
}

export default withConditionalRendering;

import axios, { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { Error as APIError } from '../model/Error';
import { useAxiosClient } from './useAxiosClient';

interface UseValidate {
  getValidateResult: (schema: string, sample: string) => Promise<APIError[]>;
}

export const useValidate = (): UseValidate => {
  const create = useAxiosClient();

  const getValidateResult = useCallback(
    async (schema: string, sample: string): Promise<APIError[]> => {
      const client = create();

      var request: any = {
        schema: schema,
        sample: sample,
      };
      try {
        const result: AxiosResponse<APIError[]> = await client.post<APIError[]>('/run-schema-validation', request);
        return result.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  return { getValidateResult };
};

export default useValidate;

const PRODUCT = 'SIP Integration Builder';
export const READ_LIVE = 'SIP_Read_Dataset_Live';
export const READ_ANY = 'SIP_Read_Dataset';
export const CREATE = 'SIP_Create_DatasetDraft_New';
export const UPDATE = 'SIP_Update_DatasetDraft';
export const PUBLISH = 'SIP_Publish_DatasetDraft_New';
export const EVALUATE = 'SIP_Evaluate_Dataset';
export const UPDATE_LOGGING_PII_LEVEL = 'SIP_Update_LoggingPIILevel';

export interface RequiredClaims {
  requiredProducts?: { [status: string]: string[] };
  requiredFunctions?: string[];
}

export const toCreate = {
  requiredFunctions: [CREATE],
};

export const toUpdate = {
  requiredFunctions: [UPDATE],
};

export const toUpdateLoggingPiiLevel = {
  requiredFunctions: [UPDATE_LOGGING_PII_LEVEL],
};

export const toPublish = {
  requiredFunctions: [PUBLISH],
};

export const toUseProduct = {
  requiredProducts: {
    Live: [PRODUCT],
  },
};

export const toEvaluate = {
  requiredFunctions: [EVALUATE],
};

import axios, { AxiosInstance } from 'axios';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { useAppSelector } from '../app/hooks';
import { env } from '../env';
import { selectIdentity } from '../store/Identity';

export const useAxiosClient = (): (() => AxiosInstance) => {
  const identity = useAppSelector(selectIdentity);

  const create = useCallback(() => {
    return axios.create({
      baseURL: `${env.REACT_APP_API_BASE_URL}/${env.REACT_APP_API_VERSION}`,
      headers: {
        'gbg-correlation-id': uuid(),
        Authorization: `Bearer ${identity.accessToken}`,
      },
    });
  }, [identity]);
  return create;
};

import React, { useRef } from 'react';
import { DialogProps } from './Dialog.types';
import { Modal, ModalHeader, ModalBody } from '../Modal/Modal';
import { AlertBar, AlertBarText, Button, ButtonKind } from '@gbg/gbgcomponentlibrary_react';

export const TestIds = {
  DISMISS_DIALOG_BUTTON: 'dismiss-dialog-button',
};

export const Dialog: React.FC<DialogProps> = ({
  dismissButtonText,
  onDismissed,
  children,
  isVisible,
  type,
  icon,
  title,
  subTitle,
  text,
  ...props
}) => {
  const ref = useRef(null);
  return (
    <Modal ref={ref} {...props} isVisible={isVisible}>
      <ModalHeader>
        <AlertBar type={type} icon={icon}>
          <AlertBarText title={title} text={subTitle} />
        </AlertBar>
      </ModalHeader>
      <ModalBody>
        <p className="paragraph-medium text-n800">{text}</p>
        <Button
          data-testid={TestIds.DISMISS_DIALOG_BUTTON}
          kind={ButtonKind.Secondary}
          className="m-m-t-4 m-m-r-1"
          onClick={() => {
            if (onDismissed) onDismissed();
          }}
        >
          {dismissButtonText ?? 'Cancel'}
        </Button>
        {children}
      </ModalBody>
    </Modal>
  );
};

export default Dialog;

import { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { Dataset } from '../../../model/Dataset';
import { Error } from '../../../model/Error';
import { FormGroup, Label, Select, ErrorText, Button, Text, Checkbox } from '@gbg/gbgcomponentlibrary_react';

export interface DatasetFormProps {
  dataset: Dataset;
  setDataset: (d: Dataset) => void;
  persist: (
    id: number,
    version: number,
    name: string,
    country: string,
    provider: string,
    pci_handler: boolean,
  ) => Promise<Error[] | undefined>;
  visibleFields: string[];
  isAuthorised: boolean;
}

const DatasetForm = ({ dataset, setDataset, persist, visibleFields, isAuthorised }: DatasetFormProps) => {
  const [error, setError] = useState<string>('');
  const setProperty = useCallback(
    (set: (d: Dataset) => void) => {
      const updatedDataset = Object.assign({}, dataset);
      set(updatedDataset);
      setDataset(updatedDataset);
    },
    [dataset, setDataset],
  );

  useEffect(() => {
    setError('');
  }, [dataset.id, dataset.description, dataset.country, dataset.provider]);

  const saveDetails = async () => {
    const [valid, invalidFields] = validate(dataset.id, dataset.description, dataset.country, dataset.provider);
    if (valid) {
      const errors = await persist(
        Number(dataset.id),
        Number(dataset.version),
        dataset.description,
        dataset.country,
        dataset.provider,
        dataset.pci_handler,
      );
      if (errors) {
        setError(errors.map((v, i) => `${v.problem}: ${v.action}`).join(', '));
      } else {
        setError('');
      }
    } else {
      setError(`These fields are invalid: ${invalidFields.join(', ')}`);
    }
  };

  return (
    <>
      {visibleFields.filter(s => s === 'id').length > 0 ? (
        <FormGroup>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(200, 1fr)' }}>
            <div>
              <Label>ID</Label>
            </div>
          </div>
          <Text
            data-testid="id"
            type="number"
            value={dataset.id || dataset.id === 0 ? dataset.id : ''}
            onChange={(e: any) => {
              const value = e.target.value !== '' ? e.target.value : null;
              setProperty(d => (d.id = value));
            }}
          />
        </FormGroup>
      ) : null}
      <FormGroup>
        <Label>Name</Label>
        <Text
          data-testid="name"
          value={dataset.description}
          onChange={(e: any) => setProperty(d => (d.description = e.target.value))}
          disabled={!(dataset.status.toLowerCase() === 'draft' && isAuthorised)}
        />
      </FormGroup>
      <FormGroup>
        <Label>Country</Label>
        <Select
          data-testid="country"
          value={dataset.country}
          disabled={!(dataset.status.toLowerCase() === 'draft' && isAuthorised)}
          onChange={(e: any) => setProperty(d => (d.country = e.target.value))}
        >
          <option value="" disabled hidden>
            Select a Country...
          </option>
          <option value="ALL">Not Country Specific (999)</option>
          <option value="GBR">United Kingdom (826)</option>
          <option value="AFG">Afghanistan (004)</option>
          <option value="ALB">Albania (008)</option>
          <option value="DZA">Algeria (012)</option>
          <option value="ASM">American Samoa (016)</option>
          <option value="AND">Andorra (020)</option>
          <option value="AGO">Angola (024)</option>
          <option value="AIA">Anguilla (660)</option>
          <option value="ATA">Antarctica (010)</option>
          <option value="ATG">Antigua and Barbuda (028)</option>
          <option value="ARG">Argentina (032)</option>
          <option value="ARM">Armenia (051)</option>
          <option value="ABW">Aruba (533)</option>
          <option value="AUS">Australia (036)</option>
          <option value="AUT">Austria (040)</option>
          <option value="AZE">Azerbaijan (031)</option>
          <option value="BHS">Bahamas (the) (044)</option>
          <option value="BHR">Bahrain (048)</option>
          <option value="BGD">Bangladesh (050)</option>
          <option value="BRB">Barbados (052)</option>
          <option value="BLR">Belarus (112)</option>
          <option value="BEL">Belgium (056)</option>
          <option value="BLZ">Belize (084)</option>
          <option value="BEN">Benin (204)</option>
          <option value="BMU">Bermuda (060)</option>
          <option value="BTN">Bhutan (064)</option>
          <option value="BOL">Bolivia (Plurinational State of) (068)</option>
          <option value="BES">Bonaire, Sint Eustatius and Saba (535)</option>
          <option value="BIH">Bosnia and Herzegovina (070)</option>
          <option value="BWA">Botswana (072)</option>
          <option value="BVT">Bouvet Island (074)</option>
          <option value="BRA">Brazil (076)</option>
          <option value="IOT">British Indian Ocean Territory (the) (086)</option>
          <option value="BRN">Brunei Darussalam (096)</option>
          <option value="BGR">Bulgaria (100)</option>
          <option value="BFA">Burkina Faso (854)</option>
          <option value="BDI">Burundi (108)</option>
          <option value="CPV">Cabo Verde (132)</option>
          <option value="KHM">Cambodia (116)</option>
          <option value="CMR">Cameroon (120)</option>
          <option value="CAN">Canada (124)</option>
          <option value="CYM">Cayman Islands (the) (136)</option>
          <option value="CAF">Central African Republic (the) (140)</option>
          <option value="TCD">Chad (148)</option>
          <option value="CHL">Chile (152)</option>
          <option value="CHN">China (156)</option>
          <option value="CXR">Christmas Island (162)</option>
          <option value="CCK">Cocos (Keeling) Islands (the) (166)</option>
          <option value="COL">Colombia (170)</option>
          <option value="COM">Comoros (the) (174)</option>
          <option value="COD">Congo (the Democratic Republic of the) (180)</option>
          <option value="COG">Congo (the) (178)</option>
          <option value="COK">Cook Islands (the) (184)</option>
          <option value="CRI">Costa Rica (188)</option>
          <option value="HRV">Croatia (191)</option>
          <option value="CUB">Cuba (192)</option>
          <option value="CUW">Curaçao (531)</option>
          <option value="CYP">Cyprus (196)</option>
          <option value="CZE">Czechia (203)</option>
          <option value="CIV">Côte d'Ivoire (384)</option>
          <option value="DNK">Denmark (208)</option>
          <option value="DJI">Djibouti (262)</option>
          <option value="DMA">Dominica (212)</option>
          <option value="DOM">Dominican Republic (the) (214)</option>
          <option value="ECU">Ecuador (218)</option>
          <option value="EGY">Egypt (818)</option>
          <option value="SLV">El Salvador (222)</option>
          <option value="GNQ">Equatorial Guinea (226)</option>
          <option value="ERI">Eritrea (232)</option>
          <option value="EST">Estonia (233)</option>
          <option value="SWZ">Eswatini (748)</option>
          <option value="ETH">Ethiopia (231)</option>
          <option value="FLK">Falkland Islands (the) [Malvinas] (238)</option>
          <option value="FRO">Faroe Islands (the) (234)</option>
          <option value="FJI">Fiji (242)</option>
          <option value="FIN">Finland (246)</option>
          <option value="FRA">France (250)</option>
          <option value="GUF">French Guiana (254)</option>
          <option value="PYF">French Polynesia (258)</option>
          <option value="ATF">French Southern Territories (the) (260)</option>
          <option value="GAB">Gabon (266)</option>
          <option value="GMB">Gambia (the) (270)</option>
          <option value="GEO">Georgia (268)</option>
          <option value="DEU">Germany (276)</option>
          <option value="GHA">Ghana (288)</option>
          <option value="GIB">Gibraltar (292)</option>
          <option value="GRC">Greece (300)</option>
          <option value="GRL">Greenland (304)</option>
          <option value="GRD">Grenada (308)</option>
          <option value="GLP">Guadeloupe (312)</option>
          <option value="GUM">Guam (316)</option>
          <option value="GTM">Guatemala (320)</option>
          <option value="GGY">Guernsey (831)</option>
          <option value="GIN">Guinea (324)</option>
          <option value="GNB">Guinea-Bissau (624)</option>
          <option value="GUY">Guyana (328)</option>
          <option value="HTI">Haiti (332)</option>
          <option value="HMD">Heard Island and McDonald Islands (334)</option>
          <option value="VAT">Holy See (the) (336)</option>
          <option value="HND">Honduras (340)</option>
          <option value="HKG">Hong Kong (344)</option>
          <option value="HUN">Hungary (348)</option>
          <option value="ISL">Iceland (352)</option>
          <option value="IND">India (356)</option>
          <option value="IDN">Indonesia (360)</option>
          <option value="IRN">Iran (Islamic Republic of) (364)</option>
          <option value="IRQ">Iraq (368)</option>
          <option value="IRL">Ireland (372)</option>
          <option value="IMN">Isle of Man (833)</option>
          <option value="ISR">Israel (376)</option>
          <option value="ITA">Italy (380)</option>
          <option value="JAM">Jamaica (388)</option>
          <option value="JPN">Japan (392)</option>
          <option value="JEY">Jersey (832)</option>
          <option value="JOR">Jordan (400)</option>
          <option value="KAZ">Kazakhstan (398)</option>
          <option value="KEN">Kenya (404)</option>
          <option value="KIR">Kiribati (296)</option>
          <option value="PRK">Korea (the Democratic People's Republic of) (408)</option>
          <option value="KOR">Korea (the Republic of) (410)</option>
          <option value="KWT">Kuwait (414)</option>
          <option value="KGZ">Kyrgyzstan (417)</option>
          <option value="LAO">Lao People's Democratic Republic (the) (418)</option>
          <option value="LVA">Latvia (428)</option>
          <option value="LBN">Lebanon (422)</option>
          <option value="LSO">Lesotho (426)</option>
          <option value="LBR">Liberia (430)</option>
          <option value="LBY">Libya (434)</option>
          <option value="LIE">Liechtenstein (438)</option>
          <option value="LTU">Lithuania (440)</option>
          <option value="LUX">Luxembourg (442)</option>
          <option value="MAC">Macao (446)</option>
          <option value="MDG">Madagascar (450)</option>
          <option value="MWI">Malawi (454)</option>
          <option value="MYS">Malaysia (458)</option>
          <option value="MDV">Maldives (462)</option>
          <option value="MLI">Mali (466)</option>
          <option value="MLT">Malta (470)</option>
          <option value="MHL">Marshall Islands (the) (584)</option>
          <option value="MTQ">Martinique (474)</option>
          <option value="MRT">Mauritania (478)</option>
          <option value="MUS">Mauritius (480)</option>
          <option value="MYT">Mayotte (175)</option>
          <option value="MEX">Mexico (484)</option>
          <option value="FSM">Micronesia (Federated States of) (583)</option>
          <option value="MDA">Moldova (the Republic of) (498)</option>
          <option value="MCO">Monaco (492)</option>
          <option value="MNG">Mongolia (496)</option>
          <option value="MNE">Montenegro (499)</option>
          <option value="MSR">Montserrat (500)</option>
          <option value="MAR">Morocco (504)</option>
          <option value="MOZ">Mozambique (508)</option>
          <option value="MMR">Myanmar (104)</option>
          <option value="NAM">Namibia (516)</option>
          <option value="NRU">Nauru (520)</option>
          <option value="NPL">Nepal (524)</option>
          <option value="NLD">Netherlands (the) (528)</option>
          <option value="NCL">New Caledonia (540)</option>
          <option value="NZL">New Zealand (554)</option>
          <option value="NIC">Nicaragua (558)</option>
          <option value="NER">Niger (the) (562)</option>
          <option value="NGA">Nigeria (566)</option>
          <option value="NIU">Niue (570)</option>
          <option value="NFK">Norfolk Island (574)</option>
          <option value="MNP">Northern Mariana Islands (the) (580)</option>
          <option value="NOR">Norway (578)</option>
          <option value="OMN">Oman (512)</option>
          <option value="PAK">Pakistan (586)</option>
          <option value="PLW">Palau (585)</option>
          <option value="PSE">Palestine, State of (275)</option>
          <option value="PAN">Panama (591)</option>
          <option value="PNG">Papua New Guinea (598)</option>
          <option value="PRY">Paraguay (600)</option>
          <option value="PER">Peru (604)</option>
          <option value="PHL">Philippines (the) (608)</option>
          <option value="PCN">Pitcairn (612)</option>
          <option value="POL">Poland (616)</option>
          <option value="PRT">Portugal (620)</option>
          <option value="PRI">Puerto Rico (630)</option>
          <option value="QAT">Qatar (634)</option>
          <option value="MKD">Republic of North Macedonia (807)</option>
          <option value="ROU">Romania (642)</option>
          <option value="RUS">Russian Federation (the) (643)</option>
          <option value="RWA">Rwanda (646)</option>
          <option value="REU">Réunion (638)</option>
          <option value="BLM">Saint Barthélemy (652)</option>
          <option value="SHN">Saint Helena, Ascension and Tristan da Cunha (654)</option>
          <option value="KNA">Saint Kitts and Nevis (659)</option>
          <option value="LCA">Saint Lucia (662)</option>
          <option value="MAF">Saint Martin (French part) (663)</option>
          <option value="SPM">Saint Pierre and Miquelon (666)</option>
          <option value="VCT">Saint Vincent and the Grenadines (670)</option>
          <option value="WSM">Samoa (882)</option>
          <option value="SMR">San Marino (674)</option>
          <option value="STP">Sao Tome and Principe (678)</option>
          <option value="SAU">Saudi Arabia (682)</option>
          <option value="SEN">Senegal (686)</option>
          <option value="SRB">Serbia (688)</option>
          <option value="SYC">Seychelles (690)</option>
          <option value="SLE">Sierra Leone (694)</option>
          <option value="SGP">Singapore (702)</option>
          <option value="SXM">Sint Maarten (Dutch part) (534)</option>
          <option value="SVK">Slovakia (703)</option>
          <option value="SVN">Slovenia (705)</option>
          <option value="SLB">Solomon Islands (090)</option>
          <option value="SOM">Somalia (706)</option>
          <option value="ZAF">South Africa (710)</option>
          <option value="SGS">South Georgia and the South Sandwich Islands (239)</option>
          <option value="SSD">South Sudan (728)</option>
          <option value="ESP">Spain (724)</option>
          <option value="LKA">Sri Lanka (144)</option>
          <option value="SDN">Sudan (the) (729)</option>
          <option value="SUR">Suriname (740)</option>
          <option value="SJM">Svalbard and Jan Mayen (744)</option>
          <option value="SWE">Sweden (752)</option>
          <option value="CHE">Switzerland (756)</option>
          <option value="SYR">Syrian Arab Republic (760)</option>
          <option value="TWN">Taiwan (Province of China) (158)</option>
          <option value="TJK">Tajikistan (762)</option>
          <option value="TZA">Tanzania, United Republic of (834)</option>
          <option value="THA">Thailand (764)</option>
          <option value="TLS">Timor-Leste (626)</option>
          <option value="TGO">Togo (768)</option>
          <option value="TKL">Tokelau (772)</option>
          <option value="TON">Tonga (776)</option>
          <option value="TTO">Trinidad and Tobago (780)</option>
          <option value="TUN">Tunisia (788)</option>
          <option value="TUR">Turkey (792)</option>
          <option value="TKM">Turkmenistan (795)</option>
          <option value="TCA">Turks and Caicos Islands (the) (796)</option>
          <option value="TUV">Tuvalu (798)</option>
          <option value="UGA">Uganda (800)</option>
          <option value="UKR">Ukraine (804)</option>
          <option value="ARE">United Arab Emirates (the) (784)</option>
          <option value="UMI">United States Minor Outlying Islands (the) (581)</option>
          <option value="USA">United States of America (the) (840)</option>
          <option value="URY">Uruguay (858)</option>
          <option value="UZB">Uzbekistan (860)</option>
          <option value="VUT">Vanuatu (548)</option>
          <option value="VEN">Venezuela (Bolivarian Republic of) (862)</option>
          <option value="VNM">Viet Nam (704)</option>
          <option value="VGB">Virgin Islands (British) (092)</option>
          <option value="VIR">Virgin Islands (U.S.) (850)</option>
          <option value="WLF">Wallis and Futuna (876)</option>
          <option value="ESH">Western Sahara (732)</option>
          <option value="YEM">Yemen (887)</option>
          <option value="ZMB">Zambia (894)</option>
          <option value="ZWE">Zimbabwe (716)</option>
          <option value="ALA">Åland Islands (248)</option>
        </Select>
      </FormGroup>
      <FormGroup>
        <Label>Provider</Label>
        <Text
          data-testid="provider"
          value={dataset.provider}
          onChange={(e: any) => setProperty(d => (d.provider = e.target.value))}
          disabled={!(dataset.status.toLowerCase() === 'draft' && isAuthorised)}
        />
      </FormGroup>
      <FormGroup>
        <div className="checkbox-item">
          <Checkbox
            data-testid="pci-checkbox"
            checked={dataset.pci_handler}
            onChange={(e: any) => setProperty(s => (s.pci_handler = e.target.checked))}
            disabled={!(dataset.status.toLowerCase() === 'draft' && isAuthorised)}
            id="pci"
          />
          <label className="checkbox-label" htmlFor="pci" data-testid="pci-checkbox-label">
            PCI Handler
          </label>
        </div>
      </FormGroup>
      <br></br>
      {saveButton(dataset, saveDetails, isAuthorised)}
      <ErrorText data-testid="error-text">{error}</ErrorText>
    </>
  );
};

const saveButton = (
  dataset: Dataset,
  saveDetails: MouseEventHandler<HTMLButtonElement> | undefined,
  isAuthorised: boolean,
) => {
  if (dataset.status.toLowerCase() === 'draft' && isAuthorised) {
    return (
      <Button data-testid="save-details" onClick={saveDetails}>
        Save details
      </Button>
    );
  }
};

const validate = (id: number | null, name: string, country: string, provider: string): [boolean, string[]] => {
  const invalidFields = [];
  if (!id || Number.isNaN(id)) {
    invalidFields.push('id');
  }
  if (!name) {
    invalidFields.push('name');
  }
  if (!country) {
    invalidFields.push('country');
  }
  if (!provider) {
    invalidFields.push('provider');
  }
  return [invalidFields.length === 0, invalidFields];
};

export default DatasetForm;

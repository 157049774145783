import './EditDataset.css';
import { useCallback, useMemo, useState } from 'react';
import { useDatasets } from '../../../hooks/useDatasets';
import { Error } from '../../../model/Error';
import { CheckResults, isCheckResults, isDatasetWithCheckResults, Status } from '../../../model/Dataset';
import {
  Button,
  ButtonKind,
  ButtonSize,
  ErrorText,
  AlertBar,
  AlertBarType,
  AlertBarText,
  Label,
} from '@gbg/gbgcomponentlibrary_react';
import CustomSpinner from '../../../components/Spinner/CustomSpinner';

const NO_ISSUES_ALERT_MESSAGE = 'Passed Validation';

function isErrorContent(object: any): object is Error[] {
  return object.map !== undefined;
}

const groupIssues = (result: CheckResults): GroupedIssues => {
  let issuesGroup: GroupedIssues = { detailsIssues: [], stepsIssues: [], validationSchemaIssues: [] };
  result.issues.forEach(issue => {
    if (issue.code >= 6000 && issue.code < 6100) {
      issuesGroup.detailsIssues.push(`${issue.location}: ${issue.problem}`);
    }
    if (issue.code >= 6100 && issue.code < 6200) {
      issuesGroup.stepsIssues.push(`${issue.location}: ${issue.problem}`);
    }
    if (issue.code >= 6200 && issue.code < 6300) {
      issuesGroup.validationSchemaIssues.push(`${issue.location}: ${issue.problem}`);
    }
  });
  return issuesGroup;
};
export interface DatasetValidationProps {
  id: number;
  version: number;
  isAuthorisedToUpdate: boolean;
  submitDataset: (status: Status) => Promise<Error[] | undefined | CheckResults>;
}

interface GroupedIssues {
  detailsIssues: string[];
  stepsIssues: string[];
  validationSchemaIssues: string[];
}

const defaultIssues = {
  detailsIssues: [],
  stepsIssues: [],
  validationSchemaIssues: [],
};

const DatasetValidation = ({ id, version, isAuthorisedToUpdate, submitDataset }: DatasetValidationProps) => {
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [validated, setValidated] = useState(false);
  const [datasetValidationError, setDatasetValidationError] = useState('');
  const [groupedIssues, setGroupedIssues] = useState<GroupedIssues>(defaultIssues);
  const { getDatasetValidationResult } = useDatasets();

  const datasetValid: boolean = useMemo(() => {
    return (
      validated &&
      groupedIssues.detailsIssues.length +
        groupedIssues.validationSchemaIssues.length +
        groupedIssues.stepsIssues.length ===
        0
    );
  }, [groupedIssues, validated]);

  const runDatasetValidation = useCallback(async () => {
    setValidationInProgress(true);
    const result = await getDatasetValidationResult(id, version);
    setValidationInProgress(false);

    if (isErrorContent(result)) {
      setValidated(false);
      setDatasetValidationError(result.map((v, i) => `${v.problem}: ${v.action}`).join(', '));
    } else if (isDatasetWithCheckResults(result)) {
      setValidated(true);
      setGroupedIssues(groupIssues(result.checkResults));
      setDatasetValidationError('');
    }
  }, [id, version, getDatasetValidationResult]);

  // TODO Future refactor to move logic within useWorkflow
  const submitDatasetToTesting = useCallback(async () => {
    setValidationInProgress(true);
    const result = await submitDataset(Status.Testing);
    setValidationInProgress(false);

    if (result !== undefined && isErrorContent(result)) {
      setValidated(false);
      setDatasetValidationError(result.map((v, i) => `${v.problem}: ${v.action}`).join(', '));
    } else if (isCheckResults(result)) {
      setDatasetValidationError('');
      if (result.issues != null) {
        setGroupedIssues(groupIssues(result));
      }
    }
  }, [submitDataset]);

  return (
    <>
      {validationInProgress ? <CustomSpinner message="Validating ... (this could take some time)" /> : null}
      {validated && !validationInProgress ? (
        <>
          <AlertsGroup label={'Details'} issues={groupedIssues.detailsIssues} />
          <AlertsGroup label={'Validation Schema'} issues={groupedIssues.validationSchemaIssues} />
          <AlertsGroup label={'Steps'} issues={groupedIssues.stepsIssues} />
        </>
      ) : null}
      {validated && datasetValid ? (
        <Button
          data-testid="validate-dataset"
          onClick={runDatasetValidation}
          kind={ButtonKind.Secondary}
          size={ButtonSize.Small}
          className={'button-margin'}
          hidden={validationInProgress}
        >
          Validate dataset
        </Button>
      ) : (
        <Button
          data-testid="validate-dataset"
          onClick={runDatasetValidation}
          size={ButtonSize.Small}
          className={'button-margin'}
          hidden={validationInProgress}
        >
          Validate dataset
        </Button>
      )}
      {validated && datasetValid && isAuthorisedToUpdate ? (
        <Button
          data-testid="submit-to-testing"
          onClick={submitDatasetToTesting}
          size={ButtonSize.Small}
          className={'button-margin'}
          hidden={validationInProgress}
        >
          Submit to testing
        </Button>
      ) : null}
      <ErrorText data-testid="dataset-validation-error-text">{datasetValidationError}</ErrorText>
    </>
  );
};

const AlertSuccess = ({ text, 'data-testid': dataTestId }: { text: string; 'data-testid': string }) => {
  return (
    <AlertBar className={'margins'} data-testid={dataTestId} type={AlertBarType.Success} icon={'checkCircle'}>
      <AlertBarText data-testid={`success-result-text`} text={text} />
    </AlertBar>
  );
};

const AlertIssue = ({ text, 'data-testid': dataTestId }: { text: string; 'data-testid': string }) => {
  return (
    <AlertBar className={'margins'} data-testid={dataTestId} type={AlertBarType.Error} icon={'alertTriangle'}>
      <AlertBarText data-testid={`issue-result-text`} text={text} />
    </AlertBar>
  );
};

const AlertsGroup = ({ label, issues }: { label: string; issues: string[] }) => {
  return (
    <>
      <Label>{label}</Label>
      {issues.length === 0 ? (
        <AlertSuccess text={NO_ISSUES_ALERT_MESSAGE} data-testid={`${label}-result-alert-bar`} />
      ) : null}
      {issues.map((issue, i) => (
        <AlertIssue key={i} text={issue} data-testid={`${label}-result-alert-bar`} />
      ))}
    </>
  );
};

export default DatasetValidation;

import './StepsTable.css';
import { TableProps } from '../../components/table/Table.types';
import { Step } from '../../model/Step';
import { useNavigate, Link } from 'react-router-dom';
import Table from '../../components/table/Table';
import classNames from 'classnames';

export interface StepsTableProps {
  isAuthorised: boolean;
  datasetId: number | null;
  datasetVersion: number | null;
  steps: Step[];
}

export const StepsTable = ({ isAuthorised, datasetId, datasetVersion, steps }: StepsTableProps) => {
  const navigate = useNavigate();

  const tableProps: TableProps<Step> = {
    testId: 'table-steps',
    dataSet: {
      data: steps,
      uniqueProperty: (item: Step) => `${item.number}`,
      headerMappings: { number: 'Number', name: 'Name' },
      renderCell: (item: Step, header: string): any => {
        if (header === 'number') {
          const stepPath = `/datasets/${datasetId}/versions/${datasetVersion}/steps/${item.number}`;
          return <Link to={stepPath}>{item.number}</Link>;
        }
        return item[header as keyof Step];
      },
      getHeaderCellClassName: (header: string): string => {
        return classNames(`table__cell--header--${header.toLowerCase()}`);
      },
      actions: (_item: Step): any => {
        const actions = [];
        if (isAuthorised) {
          const editAction = {
            title: 'Edit',
            action: (item: Step): void => {
              const datasetPath = `/datasets/${datasetId}/versions/${datasetVersion}/steps/${item.number}`;
              navigate(datasetPath);
            },
          };
          actions.push(editAction);
        }
        return actions;
      },
    },
  };
  return <Table {...tableProps} className={'w-100'}></Table>;
};

import { useAxiosClient } from './useAxiosClient';
import axios, { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { Error as APIError } from '../model/Error';
import { Step } from '../model/Step';

interface UseSteps {
  getSteps: (datasetId: number, datasetVersion: number) => Promise<Step[] | APIError[]>;
  getStep: (datasetId: number, datasetVersion: number, stepNumber: number) => Promise<Step | APIError[]>;
  upsertStep: (datasetId: number, datasetVersion: number, step: Step) => Promise<undefined | APIError[]>;
}

export const useSteps = (): UseSteps => {
  const create = useAxiosClient();

  const getSteps = useCallback(
    async (datasetId: number, datasetVersion: number): Promise<Step[] | APIError[]> => {
      const client = create();

      try {
        const response: AxiosResponse<Step[]> = await client.get<Step[]>(
          `/datasets/${datasetId}/versions/${datasetVersion}/steps`,
        );
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  const getStep = useCallback(
    async (datasetId: number, datasetVersion: number, stepNumber: number): Promise<Step | APIError[]> => {
      const client = create();
      try {
        const response: AxiosResponse<Step[]> = await client.get<Step[]>(
          `/datasets/${datasetId}/versions/${datasetVersion}/steps`,
        );
        const matchingSteps = response.data.filter(v => v.number === stepNumber);
        if (matchingSteps[0] !== undefined) {
          return matchingSteps[0];
        }
        return [{ problem: 'Step not found', code: 99999, action: 'Contact Team Rocket', location: 'API Call' }];
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  const upsertStep = useCallback(
    async (datasetId: number, datasetVersion: number, step: Step): Promise<undefined | APIError[]> => {
      const client = create();

      try {
        await client.put<undefined>(`/datasets/${datasetId}/versions/${datasetVersion}/steps/${step.number}`, {
          name: step.name,
          httpMethod: step.httpMethod,
          urls: {
            mock: `https://\${MOCK_API_DOMAIN_NAME}/\${MOCK_API_STAGE_NAME}/${datasetId}/${step.name}`,
            test: step.urls.test,
            live: step.urls.live,
          },
          piiLoggingLevel: step.piiLoggingLevel,
          auditable: step.auditable,
        });
        return;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  return { getSteps, getStep, upsertStep };
};
export default useSteps;

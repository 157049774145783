import React, { useState, KeyboardEvent, useEffect, useMemo, useRef, useCallback } from 'react';
import classnames from 'classnames';
import { TabBarProps } from './TabBar.types';
import { useLocation } from 'react-router-dom';
interface TabParComponentProps extends TabBarProps {
  updateUrl?: boolean;
}
export const TabBar: React.FC<TabParComponentProps> = ({
  tabs,
  onTabSelected,
  selectedIndex,
  className,
  updateUrl,
  ...props
}) => {
  const tabRef = useRef<HTMLUListElement>(null);
  const [selectedTab, setSelectedTab] = useState(selectedIndex ?? 0);
  const tabBarClasses = classnames('tab-bar', {}, className);
  const location = useLocation();
  const searchParam = useMemo(() => new URLSearchParams(location.search), [location]);

  const updateBrowserUrl = useCallback(
    (selectedTabItem: Element | undefined) => {
      if (!selectedTabItem) return;
      const tabElement = tabRef.current;
      const selectedTabIndex = parseInt(selectedTabItem.getAttribute('tabIndex') ?? '0');
      if (selectedTabIndex < 0 || selectedTabIndex >= tabs.length) return;
      if (selectedTabIndex < tabs.length) {
        const tabTag = tabElement?.querySelectorAll('li.tab')[selectedTabIndex].textContent ?? '';
        searchParam.set('tab', tabTag);
      }

      window.history.pushState(
        {},
        '',
        `${window.location.origin}${window.location.pathname}?${searchParam.toString()}`,
      );
    },
    [searchParam, tabs.length],
  );

  const tabClickHandler = useCallback(
    (tabIndex: number, applyUrlChange: boolean = false) => {
      setSelectedTab(tabIndex);

      if (onTabSelected) {
        onTabSelected(tabIndex);
      }
      if (applyUrlChange && updateUrl) {
        const selectedTabElement = tabRef.current?.querySelectorAll('li.tab')[tabIndex ?? 0];
        updateBrowserUrl(selectedTabElement);
      }
    },
    [onTabSelected, updateBrowserUrl, updateUrl],
  );

  useEffect(() => {
    setSelectedTab(selectedIndex ?? 0);
  }, [selectedIndex]);

  useEffect(() => {
    if (updateUrl && searchParam.get('tab')) {
      const tab = searchParam.get('tab');

      const selectedTabItem = tabRef.current?.querySelector(`li[data-tag="${tab}"]`);
      if (!selectedTabItem) return;
      const selectedTabIndex = parseInt(selectedTabItem.getAttribute('tabIndex') ?? '0');
      tabClickHandler(selectedTabIndex);
    }
  }, [tabClickHandler, updateUrl, searchParam]);

  return (
    <ul className={tabBarClasses} {...props} ref={tabRef}>
      {tabs.map((t, i) => (
        <li
          data-tag={t}
          key={t}
          tabIndex={i}
          onClick={() => tabClickHandler(i, true)}
          onKeyUp={(evt: KeyboardEvent) => {
            if (evt.key === 'Enter') {
              tabClickHandler(i, true);
            }
          }}
          className={classnames('tab', {
            'tab--active': i === selectedTab,
          })}
        >
          {t}
        </li>
      ))}
    </ul>
  );
};

export default TabBar;

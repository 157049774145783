import { useRef, useEffect } from 'react';

import { EditorView, keymap } from '@codemirror/view';
import { basicSetup } from 'codemirror';
import { velocity } from './language';
import { StreamLanguage } from '@codemirror/language';
import { indentWithTab } from '@codemirror/commands';
import './VTLEditor.css';

function isNotNull(arg: any): arg is HTMLDivElement {
  return arg !== null;
}

export interface VTLEditorProps {
  code: string;
  setCode: (t: string) => void;
  editorId?: string;
}

export const VTLEditor = ({ code, setCode, editorId = '' }: VTLEditorProps) => {
  const editor = useRef<HTMLDivElement | null>(null);

  const onUpdate = EditorView.updateListener.of(v => {
    setCode(v.state.doc.toString());
  });

  useEffect(() => {
    if (isNotNull(editor.current)) {
      const view = new EditorView({
        doc: code,
        parent: editor.current,
        extensions: [basicSetup, StreamLanguage.define(velocity), onUpdate, keymap.of([indentWithTab])],
      });
      return () => {
        view.destroy();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorId]);

  return <div ref={editor} data-testid={`vtl-editor-${editorId ?? ''}`}></div>;
};

export default VTLEditor;

import { useState, useMemo } from 'react';
import {
  Card,
  CardBody,
  NavItem,
  NavSection,
  PrimaryNavigation,
  TopBar,
  TopBarBrand,
} from '@gbg/gbgcomponentlibrary_react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Datasets from '../datasets/Datasets';
import { Identity } from '../../../model/Identity';
import AddDataset from '../datasets/AddDataset';
import CloneDataset from '../datasets/CloneDataset';
import EditDataset from '../datasets/EditDataset';
import AddStep from '../steps/AddStep';
import EditStep from '../steps/EditStep';
import EditOutputTemplate from '../steps/EditOutputTemplate';
import AddOutputTemplate from '../steps/AddOutputTemplate';
import { toEvaluate } from '../../../model/RequiredClaims';
import { isAuthorised } from '../../../services/Authorisation';
import { env } from '../../../env';

function Structure({ identity, signOut }: { identity: Identity; signOut: () => void }) {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const authorisedToEvaluate = useMemo(() => {
    const { authorised } = isAuthorised(identity, toEvaluate);
    return authorised;
  }, [identity]);

  return (
    <div className="structure" data-testid="structure">
      <TopBar
        brand={TopBarBrand.GBG}
        onNavigationToggle={() => {
          setMenuOpen(!menuOpen);
        }}
        user={{ username: identity.givenName + ' ' + identity.familyName, company: identity.companyName }}
        menu={{
          onItemSelected: (index: number, item: any) => {},
          items: [
            {
              title: 'Log out',
              onSelected: () => {
                signOut();
              },
            },
          ],
        }}
      ></TopBar>
      <PrimaryNavigation
        open={menuOpen}
        onClose={() => {
          setMenuOpen(false);
        }}
      >
        <NavSection>
          <NavItem
            data-testid="nav-datasets"
            active={location.pathname === '/datasets'}
            onClick={() => {
              navigate('/datasets');
            }}
          >
            Datasets
          </NavItem>
          {authorisedToEvaluate && (
            <NavItem
              data-testid="nav-batch-evaluator"
              onClick={() => {
                window.location.assign(env.REACT_APP_BATCH_EVALUATOR_URL);
              }}
            >
              Batch Evaluator
            </NavItem>
          )}
        </NavSection>
      </PrimaryNavigation>

      <div className="page__content">
        <Card isPrimaryContent={true}>
          <CardBody isPrimaryContent={true}>
            <Routes>
              <Route path="/datasets" element={<Datasets identity={identity} />} />
              <Route path="/datasets/add" element={<AddDataset identity={identity} />} />
              <Route
                path="/datasets/:cloneFromId/versions/:cloneFromVersion/clone"
                element={<CloneDataset identity={identity} />}
              />
              <Route
                path="/datasets/:datasetId/versions/:datasetVersion/steps/:stepNumber/output/add"
                element={<AddOutputTemplate identity={identity} />}
              />
              <Route
                path="/datasets/:datasetId/versions/:datasetVersion/steps/:stepNumber/output/:status"
                element={<EditOutputTemplate identity={identity} />}
              />
              <Route path="/datasets/:id/versions/:version/steps/add" element={<AddStep identity={identity} />} />
              <Route
                path="/datasets/:id/versions/:version/steps/:stepNumber"
                element={<EditStep identity={identity} />}
              />
              <Route path="/datasets/:id/versions/:version" element={<EditDataset identity={identity} />} />
              <Route
                path="/"
                element={
                  <>
                    <h1 data-testid="page-header">Welcome to the GBG SIP Integration Builder</h1>
                    <ul>
                      <li>Click 'Datasets' to start</li>
                    </ul>
                  </>
                }
              />
            </Routes>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default Structure;

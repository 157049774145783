import { Identity } from '../model/Identity';
import { RequiredClaims } from '../model/RequiredClaims';

export const isAuthorised = (
  identity: Identity,
  requiredClaims: RequiredClaims,
): { authorised: boolean; error?: string } => {
  const { licensedProducts, licensedFunctions } = identity;
  let missingClaims = false;
  let errorMessage = undefined;
  const missingProductLicences = checkLicencesForProducts(licensedProducts, requiredClaims.requiredProducts);
  const missingFunctionLicences = checkLicencesForFunctions(licensedFunctions, requiredClaims.requiredFunctions);

  if (missingProductLicences.length > 0 || missingFunctionLicences.length > 0) {
    missingClaims = true;
    errorMessage = 'Missing required Product or Function licence';
  }

  return { authorised: !missingClaims, error: errorMessage };
};

const checkLicencesForProducts = (
  licensedProducts: { [index: string]: string },
  requiredProducts?: { [status: string]: string[] },
): string[] => {
  const missing: string[] = [];

  if (requiredProducts) {
    for (const status of Object.keys(requiredProducts)) {
      for (const requiredProduct of requiredProducts[status]) {
        if (!Object.keys(licensedProducts).includes(requiredProduct) || licensedProducts[requiredProduct] !== status) {
          missing.push(requiredProduct);
        }
      }
    }
  }
  return missing;
};

const checkLicencesForFunctions = (licensedFunctions: string[], requiredFunctions?: string[]): string[] => {
  const missing: string[] = [];

  if (requiredFunctions) {
    for (const requiredFunction of requiredFunctions) {
      if (!licensedFunctions.includes(requiredFunction)) {
        missing.push(requiredFunction);
      }
    }
  }
  return missing;
};

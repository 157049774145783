import './OutputTemplatesTable.css';
import { TableProps } from '../../components/table/Table.types';
import { useNavigate, Link } from 'react-router-dom';
import Table from '../../components/table/Table';
import classNames from 'classnames';
import { OutputTemplate } from '../../model/Template';

export interface OutputTemplatesTableProps {
  isAuthorisedToUpdate: boolean;
  datasetId: number | null;
  datasetVersion: number | null;
  stepNumber: number | null;
  templates: OutputTemplate[];
}

export const OutputTemplatesTable = ({
  isAuthorisedToUpdate,
  datasetId,
  datasetVersion,
  stepNumber,
  templates,
}: OutputTemplatesTableProps) => {
  const navigate = useNavigate();

  const tableProps: TableProps<OutputTemplate> = {
    testId: 'table-output-templates',
    dataSet: {
      data: templates,
      uniqueProperty: (item: OutputTemplate) => `${item.status}`,
      headerMappings: { status: 'Output status code' },
      renderCell: (item: OutputTemplate, header: string): any => {
        if (header === 'status') {
          const templatePath = `/datasets/${datasetId}/versions/${datasetVersion}/steps/${stepNumber}/output/${item.status}`;
          return <Link to={templatePath}>{item.status}</Link>;
        }
        return item[header as keyof OutputTemplate];
      },
      getHeaderCellClassName: (header: string): string => {
        return classNames(`table__cell--header--${header.toLowerCase()}`);
      },
      actions: (_item: OutputTemplate): any => {
        const actions = [];
        if (isAuthorisedToUpdate) {
          const editAction = {
            title: 'Edit',
            action: (item: OutputTemplate): void => {
              const templatePath = `/datasets/${datasetId}/versions/${datasetVersion}/steps/${stepNumber}/output/${item.status}`;
              navigate(templatePath);
            },
          };
          actions.push(editAction);
        }
        return actions;
      },
    },
  };
  return <Table {...tableProps} className={'w-100'}></Table>;
};

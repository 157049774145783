import { useMemo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useTemplates from '../../../hooks/useTemplates';
import { Error as APIError } from '../../../model/Error';
import { Identity } from '../../../model/Identity';
import { toUpdate } from '../../../model/RequiredClaims';
import { isAuthorised } from '../../../services/Authorisation';
import { useDatasets } from '../../../hooks/useDatasets';
import { useSteps } from '../../../hooks/useSteps';
import { Dataset } from '../../../model/Dataset';
import { Step } from '../../../model/Step';
import './OutputTemplate.css';
import {
  toast,
  ToastPosition,
  ToastType,
  Breadcrumb,
  FormGroup,
  Label,
  Button,
  ErrorText,
  Text,
} from '@gbg/gbgcomponentlibrary_react';

const defaultDataset: Dataset = {
  id: null,
  version: null,
  description: 'Dataset',
  country: '',
  provider: '',
  status: 'draft',
};

function isErrors(arg: any): arg is APIError[] {
  return arg[0].code != null;
}

function isDataset(arg: any): arg is Dataset {
  return arg.description != null;
}

function isStep(arg: any): arg is Step {
  return arg.name != null;
}

type AddOutputTemplateParams = {
  datasetId: string;
  datasetVersion: string;
  stepNumber: string;
};

const AddOutputTemplate = ({ identity }: { identity: Identity }) => {
  const { datasetId, datasetVersion, stepNumber } = useParams<AddOutputTemplateParams>();
  const navigate = useNavigate();

  const [dataset, setDataset] = useState(defaultDataset);
  const [stepName, setStepName] = useState('');
  const [status, setStatus] = useState('');
  const [saveError, setSaveError] = useState('');
  const [datasetError, setDatasetError] = useState('');
  const [stepError, setStepError] = useState('');
  const { getDataset } = useDatasets();
  const { getStep } = useSteps();

  const { getOutputTemplates, updateOutputTemplate } = useTemplates();

  useEffect(() => {
    getDataset(Number(datasetId), Number(datasetVersion)).then(d => {
      if (isDataset(d)) {
        setDataset(d);
      } else {
        setDatasetError(`Error calling Get Dataset: ${d.map((v, i) => `${v.problem}: ${v.action}`).join(', ')}`);
      }
    });
    getStep(Number(datasetId), Number(datasetVersion), Number(stepNumber)).then(d => {
      if (isStep(d)) {
        setStepName(d.name);
      } else {
        setStepError(`Error calling Get Details: ${d.map((v, i) => `${v.problem}: ${v.action}`).join(', ')}`);
      }
    });
  }, [getDataset, getStep, datasetId, datasetVersion, stepNumber]);

  const isAuthorisedToUpdate = useMemo(() => {
    const { authorised } = isAuthorised(identity, toUpdate);
    return authorised;
  }, [identity]);

  const outputTemplateExists = async (
    datasetId: number,
    datasetVersion: number,
    stepNumber: number,
    status: number,
  ): Promise<boolean> => {
    let statusAlreadyExists = false;
    const templates = await getOutputTemplates(datasetId, datasetVersion, stepNumber);
    if (templates.length > 0) {
      if (isErrors(templates)) {
        throw new Error(
          `Error calling Get Output Templates: ${templates.map((v, i) => `${v.problem}: ${v.action}`).join(', ')}`,
        );
      } else {
        statusAlreadyExists = templates.filter(t => t.status === status).length > 0;
      }
    }
    return statusAlreadyExists;
  };

  const saveNewTemplate = async () => {
    try {
      const exists = await outputTemplateExists(
        Number(datasetId),
        Number(datasetVersion),
        Number(stepNumber),
        Number(status),
      );
      if (!exists) {
        const errors = await updateOutputTemplate(
          Number(datasetId),
          Number(datasetVersion),
          Number(stepNumber),
          Number(status),
          '',
        );
        if (!errors) {
          toast({
            title: '',
            content: `New status created`,
            position: ToastPosition.Top,
            type: ToastType.Success,
            duration: 3000,
            dismissable: false,
          });
          navigate(`/datasets/${datasetId}/versions/${datasetVersion}/steps/${stepNumber}/output/${status}`);
        } else {
          setSaveError(
            `Error calling Update Output Template: ${errors.map((v, i) => `${v.problem}: ${v.action}`).join(', ')}`,
          );
        }
      } else {
        setSaveError(`An output template for status code ${status} already exists`);
      }
    } catch (error: any) {
      setSaveError(error.message);
    }
  };

  const validateAndSave = async () => {
    if (valid(status)) {
      await saveNewTemplate();
    } else {
      setSaveError('Output status code is not valid');
    }
  };

  return (
    <>
      <Breadcrumb
        data-testid="add-output-template-breadcrumb"
        crumbs={[
          {
            title: 'Datasets',
            link: 'datasets',
          },
          {
            title: `${dataset.description}`,
            link: `datasets/${datasetId}/versions/${datasetVersion}`,
          },
          {
            title: `Steps`,
            link: `datasets/${datasetId}/versions/${datasetVersion}?tab=Steps`,
          },
          {
            title: `${stepName}`,
            link: `datasets/${datasetId}/versions/${datasetVersion}/steps/${stepNumber}`,
          },
          {
            title: 'Output',
            link: `datasets/${datasetId}/versions/${datasetVersion}/steps/${stepNumber}?tab=Output`,
          },
          {
            title: 'New output template',
            link: null,
          },
        ]}
      ></Breadcrumb>
      <div>
        <h1 data-testid="add-output-template-header">New output template</h1>
      </div>
      <FormGroup>
        <Label>Output status code</Label>
        <Text
          data-testid="status-code-text"
          className="text-status-code"
          value={status}
          onChange={(e: any) => setStatus(e.target.value)}
          disabled={!(isAuthorisedToUpdate && dataset.status.toLowerCase() === 'draft')}
        ></Text>
      </FormGroup>
      <br></br>
      {isAuthorisedToUpdate && dataset.status.toLowerCase() === 'draft' ? (
        <Button data-testid="save-output-template-button" onClick={validateAndSave}>
          Save output template
        </Button>
      ) : null}
      <ErrorText data-testid="save-output-template-error">{saveError}</ErrorText>
      <ErrorText data-testid="retrieve-dataset-error-text">{datasetError}</ErrorText>
      <ErrorText data-testid="retrieve-step-error-text">{stepError}</ErrorText>
    </>
  );
};

const valid = (status: string): boolean => {
  const regexp = new RegExp('^\\d{3}$');
  return regexp.test(status);
};

export default AddOutputTemplate;

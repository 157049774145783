import { TableHTMLAttributes } from 'react';

export interface TableProps<T = any> extends TableHTMLAttributes<HTMLTableElement> {
  testId?: string;
  hasExpandableRows?: boolean;
  dataSet?: {
    selectable?: boolean | ((item: T) => boolean);
    actions?: RowAction<T>[] | ((item: T) => RowAction<T>[]);
    data: T[];
    sortable?: boolean | string[] | ((key: string) => boolean);
    onSelectionChanged?: (selectedItems: T[]) => void;
    initialSelection?: T[] | number[] | ((dataItem: T, index: number) => boolean);
    onItemClicked?: (item: T) => void;
    uniqueProperty?: string | ((item: T) => string);
    headerMappings?: { [dataKey: string]: string };
    renderCell?: (item: T, header: string) => any;
    getHeaderCellClassName?: (header: string) => string;
    getRowCellClassName?: (item: T, header: string) => string;
  };
}

export interface RowAction<T> {
  title: string;
  action: (dataItem: T) => void;
}

export enum TableSortDirection {
  Ascending = 'Ascending',
  Descending = 'Descending',
  None = 'None',
}

import { env } from '../../env';

const config = {
  redirectUri: () => window.location.href,
  SIGNOUT_BASE_URL: async () => {
    return env.REACT_APP_IDP_SIGNOUT_BASE_URL;
  },
  AUTH_BASE_URL: async () => {
    return env.REACT_APP_IDP_SIGNIN_BASE_URL;
  },
};

export default config;

import { useCallback, useMemo, useState } from 'react';
import SvelteJsonEditor from '../../../components/jsonEditor/SvelteJSONEditor';
import VTLEditor from '../../../components/vtlEditor/VTLEditor';
import { Error } from '../../../model/Error';
import { Content, MenuItem, TextContent, MenuButtonItem } from 'svelte-jsoneditor';
import { useTransform } from '../../../hooks/useTransform';
import './TemplateForm.css';
import TabBar from '../../../components/tabs/TabBar';
import {
  Label,
  Textarea,
  Accordion,
  AccordionItem,
  FormGroup,
  Button,
  ButtonSize,
  ErrorText,
  TabPanel,
  LabelText,
  Assistive,
  Select,
  ButtonKind,
  Text,
} from '@gbg/gbgcomponentlibrary_react';

function isTextContent(arg: any): arg is TextContent {
  return arg.text !== null;
}

function isErrorContent(arg: any): arg is Error[] {
  return arg.map !== undefined;
}

function isMenuButtonItem(item: any): item is MenuButtonItem {
  return item.icon !== undefined;
}

export interface TemplateFormProps {
  datasetId: number;
  datasetVersion: number;
  templateName: string;
  template: string;
  setTemplate: (t: string) => void;
  persistTemplate: (t: string) => Promise<Error[] | undefined>;
  isAuthorised: boolean;
  stepNumber: string;
  outputStatusCode?: string;
}

function buildPreviousOutputsContainer(stepNumber: number) {
  let previousOutputContainer = '[';
  let i = 0;
  while (i < stepNumber) {
    if (i > 0) {
      previousOutputContainer = previousOutputContainer + ',';
    }
    previousOutputContainer =
      previousOutputContainer +
      '\n {\n "number":' +
      i +
      ',\n' +
      ' "sample": {"replace with output sample for step": ' +
      i +
      '}\n }';
    i++;
  }
  return previousOutputContainer + '\n]';
}

const TemplateForm = ({
  datasetId,
  datasetVersion,
  templateName,
  template,
  setTemplate,
  persistTemplate,
  isAuthorised,
  stepNumber,
  outputStatusCode,
}: TemplateFormProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [saveTemplateError, setSaveTemplateError] = useState('');
  const [transformResult, setTransformResult] = useState('');
  const [transformError, setTransformError] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [inputSample, setInputSample] = useState(
    '{\n "datasets": [\n {\n "id": ' +
      datasetId +
      ',\n "version": ' +
      datasetVersion +
      ',\n "credentials": {\n "userName": "username",\n "password": "password"\n }\n }\n ],\n "subject": {\n "person": {\n "firstName": "Joe",\n "lastNames": [\n "Bloggs2"\n ]\n },\n "identity": {\n "documents": [\n {\n "documentType": "countryId",\n "documentNumber": "37020319611025031X"\n }\n ]\n }\n }\n}',
  );
  const [supplierResponse, setSupplierResponse] = useState('');
  const [previousStepResult, setPreviousStepResult] = useState(buildPreviousOutputsContainer(Number(stepNumber)));
  const [additionalProperties, setAdditionalProperties] = useState({
    mode: '',
    correlationID: '',
    status: '',
    responseHeader: '',
  });
  const { getTransformResult } = useTransform();

  const inputSampleContent = useMemo<Content>(() => {
    return {
      text: inputSample,
    };
  }, [inputSample]);

  const setInputSampleContent = (inputSampleContent: Content) => {
    if (isTextContent(inputSampleContent)) setInputSample(inputSampleContent.text);
  };

  const previousResultContent = useMemo<Content>(() => {
    return {
      text: previousStepResult,
    };
  }, [previousStepResult]);

  const setPreviousResultContent = (previousResultContent: Content) => {
    if (isTextContent(previousResultContent)) setPreviousStepResult(previousResultContent.text);
  };

  const saveTemplate = useCallback(async () => {
    const errors = await persistTemplate(template);
    if (errors) {
      setSaveTemplateError(errors.map((v, i) => `${v.problem}: ${v.action}`).join(', '));
    } else {
      setSaveTemplateError('');
    }
  }, [template, persistTemplate]);

  const handleAdditionalPropertiesChange = (target: any) => {
    const { name, value } = target;
    setAdditionalProperties({ ...additionalProperties, [name]: value });
  };
  const testTemplate = useCallback(async () => {
    const result = await getTransformResult(
      datasetId,
      datasetVersion,
      template,
      inputSample,
      supplierResponse,
      additionalProperties.correlationID,
      additionalProperties.mode,
      additionalProperties.status,
      additionalProperties.responseHeader,
      previousStepResult,
    );
    if (isErrorContent(result)) {
      setTransformError(result.map((v, i) => `${v.problem}: ${v.action}`).join(', '));
      setShowResults(false);
    } else {
      setTransformResult(result);
      setTransformError('');
      setShowResults(true);
    }
  }, [
    datasetId,
    datasetVersion,
    inputSample,
    template,
    supplierResponse,
    previousStepResult,
    additionalProperties.correlationID,
    additionalProperties.mode,
    additionalProperties.status,
    additionalProperties.responseHeader,
    getTransformResult,
  ]);

  const ResultsLabel = () => <Label data-testid={`template-result-label`}>{`Result`}</Label>;

  const ResultsText = () => (
    <Textarea data-testid={`template-${templateName}-result`} value={String(transformResult)} readOnly={true} />
  );

  const getMenuItems = (mode: string, items: MenuItem[]): MenuItem[] | undefined => {
    const toDisplay = [
      'jsoneditor-format',
      'jsoneditor-compact',
      'magnifying-glass',
      'arrow-rotate-left',
      'arrow-rotate-right',
    ];
    const menuItems: Array<MenuItem> = [];
    items.forEach(item => {
      if (isMenuButtonItem(item) && item.icon && toDisplay.includes(item.icon?.iconName)) {
        menuItems.push(item);
      }
    });
    return menuItems.concat([{ space: true }]);
  };

  let tabnum = 0;
  let isOutput = templateName === 'Output';
  return (
    <>
      <Accordion applyTransition={true} alwaysOpen={true}>
        <AccordionItem
          header={`${templateName} template`}
          isActive={true}
          data-testid={`template-name-label-${templateName}`}
        >
          <FormGroup data-testid={`${templateName}-template-editor`}>
            <VTLEditor code={template} setCode={setTemplate} editorId={outputStatusCode} />
            {isAuthorised && (
              <Button data-testid={`save-template-${templateName}`} onClick={saveTemplate} size={ButtonSize.Small}>
                {`Save ${templateName.toLowerCase()} template`}
              </Button>
            )}
            <ErrorText data-testid={`${templateName}-save-error-text`}>{saveTemplateError}</ErrorText>
          </FormGroup>
        </AccordionItem>
        <AccordionItem header="Sample data" isActive={true}>
          <div className="test">
            <TabBar
              data-testid={`${templateName}-tab-bar`}
              onTabSelected={(i: number) => setTabIndex(i)}
              tabs={
                isOutput
                  ? ['Input body', 'Supplier response', 'Previous step result', 'Additional properties']
                  : ['Input body', 'Previous step result', 'Additional properties']
              }
            />

            <TabPanel data-testid={`${templateName}-input-tab-panel`} selectedIndex={tabIndex} index={tabnum++}>
              <LabelText>Input body</LabelText>
              <Assistive>Referenced as $payload.input_data</Assistive>
              <br></br>
              <SvelteJsonEditor
                mode="code"
                content={inputSampleContent}
                onChange={setInputSampleContent}
                readOnly={false}
                onRenderMenu={getMenuItems}
              />
            </TabPanel>
            {isOutput && (
              <TabPanel selectedIndex={tabIndex} index={tabnum++}>
                <LabelText>Supplier response</LabelText>
                <Assistive>Referenced as $payload.response</Assistive>
                <br></br>
                <Textarea
                  data-testid={`supplier-response-${templateName}`}
                  value={supplierResponse}
                  onChange={(e: any) => setSupplierResponse(e.target.value)}
                />
              </TabPanel>
            )}
            <TabPanel
              data-testid={`${templateName}-previousResult-tab-panel`}
              selectedIndex={tabIndex}
              index={tabnum++}
            >
              <LabelText>Previous step result</LabelText>
              <Assistive>Referenced as $payload.results[0]</Assistive>
              <br></br>
              <SvelteJsonEditor
                mode="code"
                content={previousResultContent}
                onChange={setPreviousResultContent}
                readOnly={false}
                onRenderMenu={getMenuItems}
              />
            </TabPanel>
            <TabPanel selectedIndex={tabIndex} index={tabnum++}>
              <div className="form-group">
                <LabelText>Correlation ID</LabelText>
                <Assistive>Referenced as $payload.correlation_id</Assistive>
                <Text
                  data-testid={`correlation-id-${templateName}`}
                  name="correlationID"
                  value={additionalProperties.correlationID}
                  onChange={(e: any) => handleAdditionalPropertiesChange(e.target)}
                />
              </div>
              <div className="form-group">
                <LabelText>Mode</LabelText>
                <Assistive>Referenced as $mode</Assistive>
                <Select
                  data-testid={`mode-${templateName}`}
                  value={additionalProperties.mode}
                  name="mode"
                  onChange={(e: any) => handleAdditionalPropertiesChange(e.target)}
                >
                  <option value="" disabled hidden>
                    Select a Mode...
                  </option>
                  <option value="live">Live</option>
                  <option value="test">Test</option>
                  <option value="mock">Mock</option>
                </Select>
              </div>
              {isOutput && (
                <>
                  <div className="form-group">
                    <LabelText>Status</LabelText>
                    <Assistive>Referenced as $payload.status_code</Assistive>
                    <Text
                      data-testid={`status-${templateName}`}
                      value={additionalProperties.status}
                      name="status"
                      onChange={(e: any) => handleAdditionalPropertiesChange(e.target)}
                    />
                  </div>
                  <div className="form-group">
                    <LabelText>Response Header</LabelText>
                    <Assistive>Referenced as $payload.response_header</Assistive>
                    <Text
                      data-testid={`response-header-${templateName}`}
                      value={additionalProperties.responseHeader}
                      placeholder='{"header_name":"header_value"}'
                      name="responseHeader"
                      onChange={(e: any) => handleAdditionalPropertiesChange(e.target)}
                    />
                  </div>
                </>
              )}
            </TabPanel>
          </div>
        </AccordionItem>
        <AccordionItem header="Results" isActive={true}>
          <>
            <Button
              data-testid={`see-result-${templateName}`}
              onClick={testTemplate}
              kind={ButtonKind.Secondary}
              size={ButtonSize.Small}
            >
              See Result
            </Button>
            {showResults ? <ResultsLabel /> : null}
            {showResults ? <ResultsText /> : null}
            <ErrorText data-testid={`${templateName}-transform-error-text`}>{transformError}</ErrorText>
          </>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default TemplateForm;

import { useAxiosClient } from './useAxiosClient';
import { Dataset, DatasetWithFullCheckResults } from '../model/Dataset';
import axios, { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { Error as APIError } from '../model/Error';

interface UseDatasets {
  getDatasets: () => Promise<Dataset[]>;
  insertDataset: (
    id: number,
    version: number,
    name: string,
    country: string,
    provider: string,
    pci_handler: boolean,
  ) => Promise<undefined | APIError[]>;
  cloneDataset: (
    id: number,
    version: number,
    name: string,
    country: string,
    provider: string,
    clonedFromId: number,
    clonedFromVersion: number,
    pci_handler: boolean,
  ) => Promise<undefined | APIError[]>;
  createNewDatasetVersion: (
    clonedFromId: number,
    clonedFromVersion: number,
    highestVersion: number,
  ) => Promise<undefined | APIError[]>;
  updateDataset: (
    id: number,
    version: number,
    name: string,
    country: string,
    provider: string,
    pci_handler: boolean,
  ) => Promise<undefined | APIError[]>;
  getDataset: (id: number, version: number) => Promise<Dataset | APIError[]>;
  getDatasetValidationResult: (
    datasetId: number | null,
    datasetVersion: number | null,
  ) => Promise<APIError[] | DatasetWithFullCheckResults>;
}

export const useDatasets = (): UseDatasets => {
  const create = useAxiosClient();

  const sleep = (time: number) => {
    return new Promise(resolve => {
      setTimeout(resolve, time);
    });
  };

  const getDatasets = useCallback(async (): Promise<Dataset[]> => {
    const client = create();

    try {
      const response: AxiosResponse<Dataset[]> = await client.get<Dataset[]>('/datasets');
      return response.data;
    } catch (error) {
      // TODO: Decide what we are going to do with errors. UX question
      console.error(error);
      return [];
    }
  }, [create]);

  const insertDataset = useCallback(
    async (
      id: number,
      version: number,
      name: string,
      country: string,
      provider: string,
      pci_handler: boolean,
    ): Promise<undefined | APIError[]> => {
      const client = create();

      try {
        await client.post<undefined>('/datasets', {
          id: id,
          version: version,
          description: name,
          country: country,
          provider: provider,
          pci_handler: pci_handler,
        });
        return;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  const cloneDataset = useCallback(
    async (
      id: number,
      version: number,
      name: string,
      country: string,
      provider: string,
      clonedFromId: number,
      clonedFromVersion: number,
      pci_handler: boolean,
    ): Promise<undefined | APIError[]> => {
      const client = create();

      try {
        await client.post<undefined>(
          '/datasets',
          {
            id: id,
            version: version,
            description: name,
            country: country,
            provider: provider,
            pci_handler: pci_handler,
          },
          {
            params: {
              'cloned-from-id': clonedFromId,
              'cloned-from-version': clonedFromVersion,
            },
          },
        );
        return;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  const createNewDatasetVersion = useCallback(
    async (clonedFromId: number, clonedFromVersion: number, latestVersion: number): Promise<undefined | APIError[]> => {
      const client = create();

      try {
        await client.post<undefined>('/datasets', null, {
          params: {
            'cloned-from-id': clonedFromId,
            'cloned-from-version': clonedFromVersion,
            'latest-version': latestVersion,
          },
        });
        return;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  const updateDataset = useCallback(
    async (
      id: number,
      version: number,
      name: string,
      country: string,
      provider: string,
      pci_handler: boolean,
    ): Promise<undefined | APIError[]> => {
      const client = create();

      try {
        await client.put<undefined>(`/datasets/${id}/versions/${version}`, {
          country: country,
          provider: provider,
          description: name,
          pci_handler: pci_handler,
        });
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  const getDataset = useCallback(
    async (id: number, version: number): Promise<Dataset | APIError[]> => {
      const client = create();
      try {
        const response: AxiosResponse<Dataset> = await client.get<Dataset>(`/datasets/${id}/versions/${version}`);
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  const getDatasetValidationResult = useCallback(
    async (
      datasetId: number | null,
      datasetVersion: number | null,
    ): Promise<APIError[] | DatasetWithFullCheckResults> => {
      const client = create();
      try {
        let responseBody: APIError[] | DatasetWithFullCheckResults = [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
        let responseStatusCode: number = 201;
        while (responseStatusCode === 201) {
          const response: AxiosResponse<DatasetWithFullCheckResults> = await client.get<DatasetWithFullCheckResults>(
            `/datasets/${datasetId}/versions/${datasetVersion}?full-check=true`,
          );
          responseStatusCode = response.status;
          if (responseStatusCode === 201) {
            await sleep(5000);
          } else {
            responseBody = response.data;
          }
        }
        return responseBody;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  return {
    getDatasets,
    insertDataset,
    cloneDataset,
    createNewDatasetVersion,
    updateDataset,
    getDataset,
    getDatasetValidationResult,
  };
};
export default useDatasets;

import axios, { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useAxiosClient } from './useAxiosClient';
import { Error } from '../model/Error';
import { OutputTemplate, Template } from '../model/Template';

function isOutputTemplates(arg: any): arg is OutputTemplate[] {
  return arg[0].status != null && arg[0].template != null;
}
interface UseTemplates {
  getHeaderTemplate: (datasetId: number, datasetVersion: number, stepNumber: number) => Promise<Template | Error[]>;
  updateHeaderTemplate: (
    datasetId: number,
    datasetVersion: number,
    stepNumber: number,
    template: string,
  ) => Promise<undefined | Error[]>;
  getInputTemplate: (datasetId: number, datasetVersion: number, stepNumber: number) => Promise<Template | Error[]>;
  updateInputTemplate: (
    datasetId: number,
    datasetVersion: number,
    stepNumber: number,
    template: string,
  ) => Promise<undefined | Error[]>;
  getOutputTemplates: (
    datasetId: number,
    datasetVersion: number,
    stepNumber: number,
  ) => Promise<OutputTemplate[] | Error[]>;
  getOutputTemplate: (
    datasetId: number,
    datasetVersion: number,
    stepNumber: number,
    status: number,
  ) => Promise<OutputTemplate | Error[]>;
  updateOutputTemplate: (
    datasetId: number,
    datasetVersion: number,
    stepNumber: number,
    statusCode: number,
    template: string,
  ) => Promise<undefined | Error[]>;
}

export const useTemplates = (): UseTemplates => {
  const create = useAxiosClient();

  const getHeaderTemplate = useCallback(
    async (datasetId: number, datasetVersion: number, stepNumber): Promise<Template | Error[]> => {
      const client = create();
      try {
        const response: AxiosResponse<Template> = await client.get<Template>(
          `/datasets/${datasetId}/versions/${datasetVersion}/steps/${stepNumber}/headerTemplate`,
        );
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  const updateHeaderTemplate = useCallback(
    async (
      datasetId: number,
      datasetVersion: number,
      stepNumber: number,
      template: string,
    ): Promise<undefined | Error[]> => {
      const client = create();
      try {
        await client.put<Template>(
          `/datasets/${datasetId}/versions/${datasetVersion}/steps/${stepNumber}/headerTemplate`,
          {
            template: template,
          },
        );
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  const getInputTemplate = useCallback(
    async (datasetId: number, datasetVersion: number, stepNumber): Promise<Template | Error[]> => {
      const client = create();
      try {
        const response: AxiosResponse<Template> = await client.get<Template>(
          `/datasets/${datasetId}/versions/${datasetVersion}/steps/${stepNumber}/inputTemplate`,
        );
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  const updateInputTemplate = useCallback(
    async (
      datasetId: number,
      datasetVersion: number,
      stepNumber: number,
      template: string,
    ): Promise<undefined | Error[]> => {
      const client = create();
      try {
        await client.put<Template>(
          `/datasets/${datasetId}/versions/${datasetVersion}/steps/${stepNumber}/inputTemplate`,
          {
            template: template,
          },
        );
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  const getOutputTemplates = useCallback(
    async (datasetId: number, datasetVersion: number, stepNumber: number): Promise<OutputTemplate[] | Error[]> => {
      const client = create();
      try {
        const response: AxiosResponse<OutputTemplate[]> = await client.get<OutputTemplate[]>(
          `/datasets/${datasetId}/versions/${datasetVersion}/steps/${stepNumber}/outputTemplates`,
        );
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  const getOutputTemplate = useCallback(
    async (
      datasetId: number,
      datasetVersion: number,
      stepNumber: number,
      status: number,
    ): Promise<OutputTemplate | Error[]> => {
      const response: OutputTemplate[] | Error[] = await getOutputTemplates(datasetId, datasetVersion, stepNumber);
      if (response.length > 0) {
        if (isOutputTemplates(response)) {
          let matchingTemplates;
          if ((matchingTemplates = response.filter(t => t.status === status)).length === 1) {
            return matchingTemplates[0];
          }
        } else {
          return response;
        }
      }
      return [
        {
          problem: 'Resource referenced in path not found',
          code: 1001,
          action: 'Please confirm the values in the path',
          location: 'Path',
        },
      ];
    },
    [getOutputTemplates],
  );

  const updateOutputTemplate = useCallback(
    async (
      datasetId: number,
      datasetVersion: number,
      stepNumber: number,
      statusCode: number,
      template: string,
    ): Promise<undefined | Error[]> => {
      const client = create();
      try {
        await client.put<Template>(
          `/datasets/${datasetId}/versions/${datasetVersion}/steps/${stepNumber}/outputTemplates/${statusCode}`,
          {
            template: template,
          },
        );
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  return {
    getHeaderTemplate,
    updateHeaderTemplate,
    getInputTemplate,
    updateInputTemplate,
    getOutputTemplates,
    getOutputTemplate,
    updateOutputTemplate,
  };
};

export default useTemplates;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import TemplateForm from './TemplateForm';
import { isAuthorised } from '../../../services/Authorisation';
import { toUpdate } from '../../../model/RequiredClaims';
import { Identity } from '../../../model/Identity';
import useTemplates from '../../../hooks/useTemplates';
import { OutputTemplate } from '../../../model/Template';
import { useDatasets } from '../../../hooks/useDatasets';
import { useSteps } from '../../../hooks/useSteps';
import { Dataset } from '../../../model/Dataset';
import { Step } from '../../../model/Step';
import './OutputTemplate.css';
import Dialog from '../../../components/Dialog/Dialog';
import {
  toast,
  ToastPosition,
  ToastType,
  Breadcrumb,
  FormGroup,
  Label,
  Select,
  ErrorText,
  AlertBarType,
  Button,
  ButtonKind,
} from '@gbg/gbgcomponentlibrary_react';

function isOutputTemplates(arg: any): arg is OutputTemplate[] {
  return arg.length > 0 && arg[0].status;
}

function isDataset(arg: any): arg is Dataset {
  return arg.description != null;
}

function isStep(arg: any): arg is Step {
  return arg.name != null;
}

const defaultDataset = {
  id: null,
  version: null,
  description: 'Dataset',
  country: '',
  provider: '',
  status: 'draft',
};

type EditOutputTemplateParams = {
  datasetId: string;
  datasetVersion: string;
  stepNumber: string;
  status: string;
};

const EditOutputTemplate = ({ identity }: { identity: Identity }) => {
  const { datasetId, datasetVersion, stepNumber, status } = useParams<EditOutputTemplateParams>();

  const [loading, setLoading] = useState(true);
  const [dataset, setDataset] = useState<Dataset>(defaultDataset);
  const [stepName, setStepName] = useState('');
  const [outputTemplate, setOutputTemplate] = useState('');
  const [outputTemplates, setOutputTemplates] = useState<OutputTemplate[]>([]);
  const [datasetError, setDatasetError] = useState('');
  const [stepError, setStepError] = useState('');
  const [outputTemplateError, setOutputTemplateError] = useState('');
  const { getDataset } = useDatasets();
  const { getStep } = useSteps();
  const [outputStatusCodes, setOutputStatusCodes] = useState<number[]>([]);
  const [selectedOutputStatusCode, setSelectedOutputStatusCode] = useState<number>(parseInt(status ?? '0'));
  const [targetStatusCode, setTargetStatusCode] = useState<number>();
  const { getOutputTemplates, updateOutputTemplate } = useTemplates();
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);

  useEffect(() => {
    getDataset(Number(datasetId), Number(datasetVersion)).then(d => {
      if (isDataset(d)) {
        setDataset(d);
      } else {
        setDatasetError(`Error calling Get Dataset: ${d.map((v, i) => `${v.problem}: ${v.action}`).join(', ')}`);
      }
    });
    getStep(Number(datasetId), Number(datasetVersion), Number(stepNumber)).then(d => {
      if (isStep(d)) {
        setStepName(d.name);
      } else {
        setStepError(`Error calling Get Details: ${d.map((v, i) => `${v.problem}: ${v.action}`).join(', ')}`);
      }
    });

    getOutputTemplates(Number(datasetId), Number(datasetVersion), Number(stepNumber)).then(result => {
      if (isOutputTemplates(result)) {
        setOutputStatusCodes(result.map(x => x.status));
        setOutputTemplates(result);
        setOutputTemplate(result.filter(x => x.status.toString() === status)[0]?.template ?? '');
      } else {
        setOutputTemplateError(
          `Error calling Get Output Template: ${result.map((v, i) => `${v.problem}: ${v.action}`).join(', ')}`,
        );
      }
      setLoading(false);
    });
  }, [
    getDataset,
    getOutputTemplates,
    getStep,
    setStepName,
    setStepError,
    stepNumber,
    datasetId,
    datasetVersion,
    status,
  ]);

  const isAuthorisedToUpdate = useMemo(() => {
    const { authorised } = isAuthorised(identity, toUpdate);
    return authorised;
  }, [identity]);

  const persistOutputTemplate = useCallback(
    async (outputTemplate: string) => {
      const errors = await updateOutputTemplate(
        Number(datasetId),
        Number(datasetVersion),
        Number(stepNumber),
        Number(selectedOutputStatusCode),
        outputTemplate,
      );
      if (!errors) {
        toast({
          title: '',
          content: `Output template has been saved`,
          position: ToastPosition.Top,
          type: ToastType.Success,
          duration: 3000,
          dismissable: false,
        });

        outputTemplates.filter(t => t.status === selectedOutputStatusCode)[0].template = outputTemplate;
        setOutputTemplates(outputTemplates);
      }
      return errors;
    },
    [datasetId, datasetVersion, stepNumber, selectedOutputStatusCode, outputTemplates, updateOutputTemplate],
  );

  const onChangeStatusCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentTemplate = outputTemplates.filter(t => t.status === selectedOutputStatusCode)[0]?.template ?? '';
    const targetStatusCode = parseInt(e.target.value);
    if (currentTemplate.trim() !== outputTemplate.trim()) {
      setTargetStatusCode(targetStatusCode);
      setConfirmationDialogVisible(true);
    } else {
      setActiveStatusTemplate(targetStatusCode);
    }
  };

  const setActiveStatusTemplate = (statusCode?: number) => {
    if (!statusCode) statusCode = targetStatusCode;
    if (statusCode) {
      const currentUrl = window.location.href;
      const updatedUrl = currentUrl.replace(`output/${selectedOutputStatusCode}`, `output/${statusCode}`);

      const targetTemplate = outputTemplates.filter(x => x.status === statusCode)[0]?.template ?? '';

      setSelectedOutputStatusCode(statusCode);
      setOutputTemplate(targetTemplate);
      setConfirmationDialogVisible(false);
      window.history.replaceState('', 'New Page Title', updatedUrl);
    }
  };

  return (
    <>
      <Breadcrumb
        data-testid="edit-output-template-breadcrumb"
        crumbs={[
          {
            title: 'Datasets',
            link: 'datasets',
          },
          {
            title: `${dataset.description}`,
            link: `datasets/${datasetId}/versions/${datasetVersion}`,
          },
          {
            title: `Steps`,
            link: `datasets/${datasetId}/versions/${datasetVersion}?tab=Steps`,
          },
          {
            title: `${stepName}`,
            link: `datasets/${datasetId}/versions/${datasetVersion}/steps/${stepNumber}`,
          },
          {
            title: 'Output',
            link: `datasets/${datasetId}/versions/${datasetVersion}/steps/${stepNumber}?tab=Output`,
          },
          {
            title: 'Output template',
            link: null,
          },
        ]}
      ></Breadcrumb>
      {!loading && stepNumber ? (
        <>
          <div>
            <h1 data-testid="edit-output-template-header">Output template</h1>
          </div>
          <FormGroup>
            <Label>Output status code</Label>
            <Select
              data-testid="status-code-select"
              className="select-status-code"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeStatusCode(e)}
              value={selectedOutputStatusCode}
            >
              {outputStatusCodes.map(x => (
                <option value={x} data-testid="status-code-select-option">
                  {x}
                </option>
              ))}
            </Select>
          </FormGroup>

          <TemplateForm
            datasetId={Number(datasetId)}
            datasetVersion={Number(datasetVersion)}
            templateName="Output"
            template={outputTemplate}
            setTemplate={setOutputTemplate}
            persistTemplate={persistOutputTemplate}
            outputStatusCode={selectedOutputStatusCode?.toString() ?? ''}
            isAuthorised={isAuthorisedToUpdate && dataset.status.toLowerCase() === 'draft'}
            stepNumber={stepNumber}
          />
          <ErrorText data-testid="retrieve-output-template-error-text">{outputTemplateError}</ErrorText>
        </>
      ) : null}
      <ErrorText data-testid="retrieve-dataset-error-text">{datasetError}</ErrorText>
      <ErrorText data-testid="retrieve-step-error-text">{stepError}</ErrorText>
      <Dialog
        data-testid="confirm-save-output-template-dialog"
        isVisible={confirmationDialogVisible}
        type={AlertBarType.Warn}
        icon={'alertTriangle'}
        title={'Are you sure you want to change status code?'}
        text={'You have unsaved changes. '}
        onDismissed={() => setConfirmationDialogVisible(false)}
        dismissButtonText={'Cancel'}
      >
        <Button
          data-testid="confirm-save-output-template-button"
          kind={ButtonKind.Destructive}
          onClick={() => setActiveStatusTemplate()}
          className={'button-margin'}
        >
          I understand, proceed
        </Button>
      </Dialog>
    </>
  );
};

export default EditOutputTemplate;

import { useEffect, useMemo, useState } from 'react';
import { DatasetsTable } from '../../datasets-table/DatasetsTable';
import { Dataset } from '../../../model/Dataset';
import { useDatasets } from '../../../hooks/useDatasets';
import { useNavigate } from 'react-router-dom';
import { Identity } from '../../../model/Identity';
import { isAuthorised } from '../../../services/Authorisation';
import { toCreate, toUpdate } from '../../../model/RequiredClaims';
import { Button } from '@gbg/gbgcomponentlibrary_react';

function Datasets({ identity }: { identity: Identity }) {
  const [data, setData] = useState<Dataset[]>([]);
  const { getDatasets } = useDatasets();
  const navigate = useNavigate();
  const isAuthorisedToCreate = useMemo(() => {
    const { authorised } = isAuthorised(identity, toCreate);
    return authorised;
  }, [identity]);
  const isAuthorisedToUpdate = useMemo(() => {
    const { authorised } = isAuthorised(identity, toUpdate);
    return authorised;
  }, [identity]);

  useEffect(() => {
    let mounted = true;
    getDatasets().then(datasets => {
      if (mounted) setData(datasets);
    });
    return () => {
      mounted = false;
    };
  }, [setData, getDatasets]);

  return (
    <>
      <h1 data-testid="page-header">Datasets</h1>
      <DatasetsTable
        data-testid="datasets-table"
        datasets={data}
        isAuthorisedToCreate={isAuthorisedToCreate}
        isAuthorisedToUpdate={isAuthorisedToUpdate}
      ></DatasetsTable>
      <br></br>
      <Button
        data-testid="add-dataset-button"
        disabled={!isAuthorisedToCreate}
        onClick={() => {
          navigate('/datasets/add');
        }}
      >
        Add new dataset
      </Button>
    </>
  );
}

export default Datasets;

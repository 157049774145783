import './Dataset.css';
import { useCallback, useMemo, useState } from 'react';
import { Dataset } from '../../../model/Dataset';
import { useDatasets } from '../../../hooks/useDatasets';
import DatasetForm from '../datasetForm/DatasetForm';
import { useNavigate, useParams } from 'react-router-dom';
import { Identity } from '../../../model/Identity';
import { isAuthorised } from '../../../services/Authorisation';
import { toCreate } from '../../../model/RequiredClaims';
import { toast, ToastPosition, ToastType, Pill, PillType } from '@gbg/gbgcomponentlibrary_react';

const newDataset = {
  id: null,
  version: 1,
  description: '',
  country: '',
  provider: '',
  status: 'DRAFT',
  pci_handler: false,
};

type CloneDatasetParams = {
  cloneFromId: string;
  cloneFromVersion: string;
};

const CloneDataset = ({ identity }: { identity: Identity }) => {
  const { cloneFromId, cloneFromVersion } = useParams<CloneDatasetParams>();
  const [dataset, setDataset] = useState<Dataset>(newDataset);
  const { cloneDataset } = useDatasets();
  const navigate = useNavigate();

  const isAuthorisedToCreate = useMemo(() => {
    const { authorised } = isAuthorised(identity, toCreate);
    return authorised;
  }, [identity]);

  const persistDetails = useCallback(
    async (id: number, version: number, name: string, country: string, provider: string, pci_handler: boolean) => {
      const errors = await cloneDataset(
        id,
        version,
        name,
        country,
        provider,
        Number(cloneFromId),
        Number(cloneFromVersion),
        pci_handler,
      );
      if (!errors) {
        toast({
          title: '',
          content: `${name} has been saved`,
          position: ToastPosition.Top,
          type: ToastType.Success,
          duration: 3000,
          dismissable: false,
        });
        navigate(`/datasets/${id}/versions/${version}`);
      }
      return errors;
    },
    [cloneDataset, cloneFromId, cloneFromVersion, navigate],
  );

  return (
    <>
      <div>
        <div>
          <h1 data-testid="clone-dataset-header">Clone Dataset</h1>
          <Pill data-testid="id-pill" type={PillType.Info}>
            FROM ID {cloneFromId}
          </Pill>
          <Pill data-testid="version-pill" type={PillType.Info}>
            VERSION {cloneFromVersion}
          </Pill>
        </div>
      </div>
      <DatasetForm
        dataset={dataset}
        isAuthorised={isAuthorisedToCreate}
        setDataset={setDataset}
        persist={persistDetails}
        visibleFields={['id']}
      />
    </>
  );
};

export default CloneDataset;

import axios, { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { VTLResult } from '../model/VTLResult';
import { useAxiosClient } from './useAxiosClient';
import { Error as APIError } from '../model/Error';

interface UseTransform {
  getTransformResult: (
    datasetId: number,
    datasetVersion: number,
    template: string,
    input: string,
    response: string,
    correlationID: string,
    mode: string,
    status: string,
    responseHeader: string,
    stepResults: string,
  ) => Promise<APIError[] | string>;
}

export const useTransform = (): UseTransform => {
  const create = useAxiosClient();

  const getTransformResult = useCallback(
    async (
      datasetId: number,
      datasetVersion: number,
      template: string,
      input: string,
      response: string,
      correlationID: string,
      mode: string,
      status: string,
      responseHeader: string,
      stepResults: string,
    ): Promise<APIError[] | string> => {
      const client = create();

      var request: any = {
        datasetId,
        datasetVersion,
        template: template,
        inputSample: input,
      };
      if (response !== '') {
        request.responseSample = response;
      }
      if (correlationID !== '') {
        request.correlationID = correlationID;
      }
      request.mode = mode;
      if (status !== '') {
        request.statusCode = status;
      }
      if (responseHeader !== '') {
        request.responseHeader = responseHeader;
      }
      if (stepResults !== '') {
        request.stepResults = stepResults;
      }
      try {
        const result: AxiosResponse<VTLResult> = await client.post<VTLResult>('/run-vtl', request);
        return result.data.result;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          {
            problem: 'Unexpected error calling API',
            code: 5001,
            action: 'Please contact the squad',
            location: 'API Call',
          },
        ];
      }
    },
    [create],
  );

  return { getTransformResult };
};

export default useTransform;

import { Card, CardBody, PrimaryNavigation, TopBar } from '@gbg/gbgcomponentlibrary_react';

interface ErrorData {
  header: string;
  detailMessage: string;
}

function Error({ header, detailMessage }: ErrorData) {
  return (
    <div className="structure" data-testid="structure">
      <TopBar includeNavLink={false} />
      <PrimaryNavigation />

      <div className="page__content">
        <Card isPrimaryContent={true}>
          <CardBody isPrimaryContent={true}>
            <h1 data-testid="error-page-header">{header}</h1>
            <p data-testid="error-detail-message">{detailMessage}</p>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default Error;

import React, { useState } from 'react';
import Table from '../../components/table/Table';
import { TableProps } from '../../components/table/Table.types';
import { useNavigate, Link } from 'react-router-dom';
import { Dataset } from '../../model/Dataset';
import {
  AlertBarType,
  Button,
  ButtonKind,
  IconKeys,
  Pill,
  PillType,
  ToastPosition,
  ToastType,
  toast,
} from '@gbg/gbgcomponentlibrary_react';
import Dialog from '../../components/Dialog/Dialog';
import useDatasets from '../../hooks/useDatasets';

const dateFormatter = new Intl.DateTimeFormat('en-GB', {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
});

const getPillTypeForStatus = (status: string | undefined): PillType => {
  let pillType = PillType.Dark;
  if (status) {
    status = status.toLowerCase();
    switch (status) {
      case 'live':
        pillType = PillType.Success;
        break;
      case 'draft':
        pillType = PillType.Warn;
        break;
      case 'testing':
        pillType = PillType.Error;
        break;
      case 'approval':
        pillType = PillType.Info;
        break;
      case 'retired':
      default:
        pillType = PillType.Dark;
    }
  }
  return pillType;
};

export interface DatasetsTableParams {
  datasets: Dataset[];
  isAuthorisedToCreate: boolean;
  isAuthorisedToUpdate: boolean;
}

export const DatasetsTable: React.FC<DatasetsTableParams> = ({
  datasets,
  isAuthorisedToCreate,
  isAuthorisedToUpdate,
}) => {
  const navigate = useNavigate();
  const [isNewVersionDialogVisible, setIsNewVersionDialogVisible] = useState<boolean>(false);
  const { createNewDatasetVersion } = useDatasets();
  const [error, setError] = useState<string | null>(null);
  const [selectedDataset, setSelectedDataset] = useState<{ dataset: Dataset; highestVersion: number } | null>(null);

  const createNewVersion = async () => {
    if (
      !selectedDataset ||
      !selectedDataset.dataset.id ||
      !selectedDataset.dataset.version ||
      !selectedDataset.highestVersion
    )
      return;
    const errors = await createNewDatasetVersion(
      selectedDataset?.dataset.id,
      selectedDataset.dataset.version,
      selectedDataset?.highestVersion,
    );

    if (!errors) {
      toast({
        title: '',
        content: `New dataset version has been created`,
        position: ToastPosition.Top,
        type: ToastType.Success,
        duration: 3000,
        dismissable: false,
      });

      setError(null);
      setIsNewVersionDialogVisible(false);
      navigate(`/datasets/${selectedDataset.dataset.id}/versions/${selectedDataset.highestVersion}`);
      return;
    }

    setError(errors.map(x => x.problem).join(' ,'));
  };

  const getDialogProps = () => {
    if (error) return { type: AlertBarType.Error, icon: IconKeys.XOctagon, title: 'An error occurred', text: error };
    else
      return {
        ...{
          type: AlertBarType.Warn,
          icon: IconKeys.HelpCircle,
          title: 'Create new version',
          subTitle: 'Are you sure?',
          text: `You are about to create version ${selectedDataset?.highestVersion} of dataset ${selectedDataset?.dataset.id}`,
        },
      };
  };

  const tableProps: TableProps<Dataset> = {
    testId: 'table-datasets',
    dataSet: {
      data: datasets,
      uniqueProperty: (item: Dataset) => `${item.id}-${item.version}`,
      headerMappings: {
        id: 'ID',
        description: 'Name',
        version: 'Version',
        country: 'Country',
        provider: 'Provider',
        status: 'Status',
        lastUpdated: 'Last updated',
      },
      renderCell: (item: Dataset, header: string): any => {
        if (header === 'id') {
          let datasetPath = `/datasets/${item.id}/versions/${item.version}`;
          return <Link to={datasetPath}>{item.id}</Link>;
        } else if (header === 'status') {
          return <Pill type={getPillTypeForStatus(item.status)}>{item.status?.toUpperCase()}</Pill>;
        } else if (header === 'lastUpdated' && item.lastUpdated !== undefined && item.lastUpdated.length > 0) {
          const date = new Date(item.lastUpdated);
          let formattedDate = '';
          try {
            formattedDate = dateFormatter.format(date);
          } catch (error) {
            // No action required as we will return the default
          }
          return formattedDate;
        }
        return item[header as keyof Dataset];
      },
      actions: (item: Dataset): any => {
        const availableActions = [];
        if (isAuthorisedToCreate) {
          availableActions.push({
            title: 'Clone',
            action: (): void => {
              let datasetPath = `/datasets/${item.id}/versions/${item.version}/clone`;
              navigate(datasetPath);
            },
          });

          availableActions.push({
            title: 'Create New Version',
            action: (): void => {
              const allDatasetVersions = datasets
                .filter(x => x.id === item.id)
                .sort((a, b) => (a.version ?? 0) - (b.version ?? 0));
              if (allDatasetVersions) {
                const lastDatasetVersion = (allDatasetVersions.pop()?.version ?? 0) + 1;
                setSelectedDataset({ dataset: item, highestVersion: lastDatasetVersion });
                setIsNewVersionDialogVisible(true);
              }
            },
          });
        }

        if (item.status.toLowerCase() === 'draft' && isAuthorisedToUpdate) {
          availableActions.push({
            title: 'Edit',
            action: (item: Dataset): void => {
              let datasetPath = `/datasets/${item.id}/versions/${item.version}`;
              navigate(datasetPath);
            },
          });
        }

        return availableActions;
      },
    },
  };
  return (
    <>
      <Dialog
        data-testid="create-new-version-dialog"
        isVisible={isNewVersionDialogVisible}
        {...getDialogProps()}
        onDismissed={() => {
          setIsNewVersionDialogVisible(false);
          setError(null);
        }}
        dismissButtonText={'Cancel'}
      >
        {!error && (
          <Button
            data-testid="create-new-version-confirm-btn"
            kind={ButtonKind.Primary}
            onClick={() => createNewVersion()}
          >
            Confirm
          </Button>
        )}
      </Dialog>
      <Table {...tableProps}></Table>
    </>
  );
};

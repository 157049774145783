import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { TableProps, TableSortDirection } from './Table.types';
import {
  TableHead,
  TableRow,
  TableHeaderCell,
  Checkbox,
  TableBody,
  TableCell,
  OverflowMenu,
} from '@gbg/gbgcomponentlibrary_react';

export const Table: React.FC<TableProps> = React.forwardRef<HTMLTableElement, TableProps>(
  ({ children, testId, dataSet, hasExpandableRows, className, ...props }, ref) => {
    const tableClasses = classnames(
      `table`,
      {
        'table-expandable': hasExpandableRows,
      },
      className,
    );
    const tableTestId = testId ?? 'table';
    let headers: string[] = [];
    let headerMappings: { [dataKey: string]: string } = {};
    if (dataSet?.headerMappings) {
      headerMappings = dataSet.headerMappings;
      headers = Object.keys(headerMappings);
    } else if (dataSet) {
      const keys: string[] = Array<string>().concat.apply(
        [],
        dataSet.data.map(di => Object.keys(di)),
      );
      const dedupedKeys = keys.filter((item, pos) => {
        return keys.indexOf(item) === pos;
      });

      headers = dedupedKeys;
    }

    const getUniqueKey = (rowData: any, rowIndex: number) => {
      if (typeof dataSet?.uniqueProperty === 'function') {
        return dataSet.uniqueProperty(rowData);
      } else if (dataSet && dataSet.uniqueProperty && rowData[dataSet.uniqueProperty]) {
        return rowData[dataSet.uniqueProperty];
      } else {
        return rowIndex;
      }
    };

    const getHeaderName = (headerProperty: string): string => {
      if (headerProperty in headerMappings) return headerMappings[headerProperty];

      return headerProperty;
    };

    const [sortedData, setSortedData] = useState<any[] | null>(dataSet ? dataSet.data : null);
    const [sortHeader, setSortHeader] = useState<string>(headers[0]);
    const [sortDirection, setSortDirection] = useState<TableSortDirection>(TableSortDirection.Ascending);
    const [selectedItems, setSelectedItems] = useState<any[]>(
      dataSet?.data?.filter((di, i) => {
        if (
          !dataSet.initialSelection ||
          (Array.isArray(dataSet.initialSelection) && dataSet.initialSelection.length === 0)
        )
          return false;
        if (!Array.isArray(dataSet.initialSelection)) {
          return dataSet.initialSelection(di, i);
        }
        if (typeof dataSet.initialSelection[0] === 'number') {
          return dataSet.initialSelection.indexOf(i) > -1;
        } else {
          return dataSet.initialSelection.indexOf(di) > -1;
        }
      }) ?? [],
    ); //TODO type this whole thing???

    useEffect(() => {
      if (dataSet) {
        const sd = [...dataSet.data].sort((a, b) => {
          if (a[sortHeader] === b[sortHeader]) return 0;
          if (a[sortHeader] < b[sortHeader]) return sortDirection === TableSortDirection.Ascending ? -1 : 1;
          return sortDirection === TableSortDirection.Ascending ? 1 : -1;
        });
        setSortedData(sd);
      }
    }, [sortHeader, sortDirection, dataSet]);

    const cellHeaderHandler = (key: string) => {
      if (!cellIsSortable(key)) return;
      setSortHeader(key);
      setSortDirection(
        sortDirection === TableSortDirection.None || sortDirection === TableSortDirection.Descending
          ? TableSortDirection.Ascending
          : TableSortDirection.Descending,
      );
    };

    const renderValue = (obj: any): any => {
      if (obj instanceof Date) return obj.toLocaleDateString();
      if (typeof obj === 'function') return renderValue(obj());
      return obj;
    };

    const renderKeyValue = (obj: any, key: string): any => {
      if (!obj.hasOwnProperty(key)) return null;
      if (dataSet && dataSet.renderCell) return renderValue(dataSet.renderCell(obj, key));
      else return renderValue(obj[key]);
    };

    const cellIsSortable = (key: string): boolean => {
      if (dataSet) {
        if (typeof dataSet.sortable === 'boolean') return dataSet.sortable;
        if (Array.isArray(dataSet.sortable)) return dataSet.sortable.includes(key);
        if (typeof dataSet.sortable === 'function') return dataSet.sortable(key);
      }
      return false;
    };

    const rowIsSelectable = (item: any): boolean => {
      if (dataSet) {
        if (typeof dataSet.selectable === 'boolean') return dataSet.selectable;
        if (typeof dataSet.selectable === 'function') return dataSet.selectable(item);
      }
      return false;
    };

    const anyRowIsSelectable = () => {
      return sortedData && sortedData.map(di => rowIsSelectable(di)).includes(true);
    };

    const getHeaderCellClassName = (header: string) => {
      if (dataSet && dataSet.getHeaderCellClassName && typeof dataSet.getHeaderCellClassName === 'function') {
        return dataSet.getHeaderCellClassName(header);
      }
      return '';
    };

    const getRowCellClassName = (item: any, header: string) => {
      if (dataSet && dataSet.getRowCellClassName && typeof dataSet.getRowCellClassName === 'function') {
        return dataSet.getRowCellClassName(item, header);
      }
      return '';
    };

    var index = 0;
    return (
      <table data-testid={tableTestId} className={tableClasses} {...props} ref={ref}>
        {dataSet && sortedData ? (
          <>
            <TableHead>
              <TableRow isHeader={true}>
                {anyRowIsSelectable() ? (
                  <TableHeaderCell>
                    <Checkbox
                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        const newSelectedItems = evt.target.checked ? dataSet.data : [];
                        setSelectedItems(newSelectedItems);
                        if (dataSet.onSelectionChanged) {
                          dataSet.onSelectionChanged(newSelectedItems);
                        }
                      }}
                      disabled={false}
                      error={false}
                    />
                  </TableHeaderCell>
                ) : null}
                {headers.map(key => (
                  <TableHeaderCell
                    key={key}
                    onClick={() => {
                      cellHeaderHandler(key);
                    }}
                    sortDirection={key === sortHeader ? sortDirection : TableSortDirection.None}
                    sortable={cellIsSortable(key)}
                    className={getHeaderCellClassName(key)}
                  >
                    {getHeaderName(key)}
                  </TableHeaderCell>
                ))}
                {dataSet.actions && dataSet.actions.length ? <TableHeaderCell>Actions</TableHeaderCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map(di => (
                <TableRow key={getUniqueKey(di, index++)}>
                  {rowIsSelectable(di) ? (
                    <TableCell>
                      <Checkbox
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                          const newSelectedItems = evt.target.checked
                            ? [...selectedItems, di]
                            : selectedItems.filter(i => i !== di);
                          setSelectedItems(newSelectedItems);
                          if (dataSet.onSelectionChanged) {
                            dataSet.onSelectionChanged(newSelectedItems);
                          }
                        }}
                        checked={selectedItems.includes(di)}
                        disabled={false}
                        error={false}
                      />
                    </TableCell>
                  ) : null}
                  {headers.map(header => (
                    <TableCell
                      key={header}
                      onClick={() => {
                        if (dataSet.onItemClicked) {
                          dataSet.onItemClicked(di);
                        }
                      }}
                      className={getRowCellClassName(di, header)}
                    >
                      {renderKeyValue(di, header)}
                    </TableCell>
                  ))}
                  {dataSet.actions && dataSet.actions.length ? (
                    <TableCell buttons={true}>
                      <OverflowMenu
                        data-testid="overflow-menu"
                        onItemSelected={(index: number, menuItem: any) => {
                          menuItem.action(di);
                        }}
                        items={typeof dataSet.actions == 'function' ? dataSet.actions(di) : dataSet.actions}
                      ></OverflowMenu>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          </>
        ) : (
          children
        )}
      </table>
    );
  },
);

export default Table;

export interface Urls {
  test: string;
  mock: string;
  live: string;
}

export interface Step {
  name: string;
  httpMethod: string;
  number: number;
  urls: Urls;
  piiLoggingLevel: PiiLoggingLevel;
  auditable: boolean;
}

export enum PiiLoggingLevel {
  OFF = 'OFF',
  FAILURES_ONLY = 'FAILURES_ONLY',
  EXCEPT_ON_SUCCESS = 'EXCEPT_ON_SUCCESS',
}

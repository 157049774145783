import { useCallback, useEffect, useState } from 'react';
import { PiiLoggingLevel, Step } from '../../../model/Step';
import { Error } from '../../../model/Error';
import { IconKeys } from '@gbg/gbgcomponentlibrary/src/tokens/ts/_icons';
import './StepForm.css';

import Dialog, { TestIds as DialogTestIds } from '../../../components/Dialog/Dialog';
import {
  FormGroup,
  Label,
  Select,
  Assistive,
  Icon,
  Radio,
  Button,
  ErrorText,
  AlertBarType,
  ButtonKind,
  Text,
  Checkbox,
} from '@gbg/gbgcomponentlibrary_react';

export const TestIds = {
  CONFIRM_SAVE_DIALOG: 'confirm-save-templated-url-dialog',
  CONFIRM_SAVE_BUTTON: 'confirm-save-templated-url-button',
  ...DialogTestIds,
};
export interface StepFormProps {
  datasetId: string;
  datasetVersion: string;
  step: Step;
  setStep: (s: Step) => void;
  persist: (datasetId: number, datasetVersion: number, step: Step) => Promise<Error[] | undefined>;
  isAuthorised: boolean;
  isAuthorisedToUpdatePiiLogging?: boolean;
  icon?: IconKeys | string;
}

const StepForm = ({
  datasetId,
  datasetVersion,
  step,
  setStep,
  persist,
  isAuthorised,
  icon,
  isAuthorisedToUpdatePiiLogging = isAuthorised,
}: StepFormProps) => {
  const [error, setError] = useState<string>('');
  const setProperty = useCallback(
    (set: (s: Step) => void) => {
      const updatedStep = Object.assign({}, step);
      set(updatedStep);
      setStep(updatedStep);
    },
    [step, setStep],
  );
  icon = 'alertTriangle';
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);

  useEffect(() => {
    setError('');
  }, [step.name, step.httpMethod, step.urls.test, step.urls.live, step.piiLoggingLevel]);

  const validateAndSaveDetails = async () => {
    const [valid, invalidFields] = validateFieldsNotEmpty();
    if (valid) {
      if (validateURLTemplated(step.urls.test, step.urls.live)) {
        setConfirmationDialogVisible(true);
      } else {
        saveDetails();
      }
    } else {
      setError(`These fields are invalid: ${invalidFields.join(', ')}`);
    }
  };

  const validateFieldsNotEmpty = (): [boolean, string[]] => {
    const invalidFields = [];
    if (!step.name) {
      invalidFields.push('name');
    }
    if (!step.httpMethod) {
      invalidFields.push('HTTP method');
    }
    if (!step.urls.test) {
      invalidFields.push('test URL');
    }
    if (!step.urls.live) {
      invalidFields.push('live URL');
    }

    return [invalidFields.length === 0, invalidFields];
  };

  var chooseHttpMethodValue = (httpMethod: string) => {
    if (httpMethod && typeof httpMethod === 'string') {
      return httpMethod;
    }
    return '';
  };

  const validateURLTemplated = (testUrl: string, liveUrl: string): boolean => {
    return testUrl.includes('#') || testUrl.includes('$') || liveUrl.includes('#') || liveUrl.includes('$');
  };

  const saveDetails = async () => {
    if (confirmationDialogVisible) {
      setConfirmationDialogVisible(false);
    }
    const errors = await persist(Number(datasetId), Number(datasetVersion), step);
    if (errors) {
      setError(errors.map((e: { problem: string; action: string }) => `${e.problem}: ${e.action}`).join(', '));
    } else {
      setError('');
    }
  };

  return (
    <>
      <FormGroup>
        <Label>Name</Label>
        <Text
          data-testid="name"
          value={step.name}
          onChange={(e: any) => setProperty(s => (s.name = e.target.value))}
          disabled={!isAuthorised}
        />
      </FormGroup>
      <FormGroup>
        <Label>HTTP Method</Label>
        <Select
          className="short-select"
          data-testid="http-method-select"
          value={chooseHttpMethodValue(step.httpMethod)}
          disabled={!isAuthorised}
          onChange={(e: any) => setProperty(s => (s.httpMethod = e.target.value))}
        >
          <option value="" disabled hidden>
            Select an HTTP method...
          </option>
          <option value="GET">GET</option>
          <option value="POST">POST</option>
        </Select>
      </FormGroup>
      <h2>URL Endpoints</h2>
      <FormGroup>
        <Label>Test URL</Label>
        <Text
          data-testid="test-url"
          value={step.urls.test}
          onChange={(e: any) => setProperty(s => (s.urls.test = e.target.value))}
          disabled={!isAuthorised}
        />
      </FormGroup>
      <FormGroup>
        <Label>Live URL</Label>
        <Text
          data-testid="live-url"
          value={step.urls.live}
          onChange={(e: any) => setProperty(s => (s.urls.live = e.target.value))}
          disabled={!isAuthorised}
        />
      </FormGroup>
      <FormGroup>
        <div className="checkbox-item">
          <Checkbox
            data-testid="auditable-checkbox"
            checked={step.auditable}
            onChange={(e: any) => setProperty(s => (s.auditable = e.target.checked))}
            disabled={!isAuthorised}
            id="auditable"
          />
          <label className="checkbox-label" htmlFor="auditable" data-testid="auditable-checkbox-label">
            Auditable Step
          </label>
        </div>
      </FormGroup>
      <br></br>
      <FormGroup>
        <Label>Log payload</Label>
        <div className="log-payload-label">
          <Assistive>
            <Icon icon={icon}></Icon>This will store PII in logs for 7 days for 'failed' transactions
          </Assistive>
        </div>
        <div className="radio-button-div">
          <Label data-testid="no-log-payload-label">
            <Radio
              data-testid="no-log-payload"
              type="radio"
              value={PiiLoggingLevel.OFF}
              name="piiLoggingLevel"
              defaultChecked={step.piiLoggingLevel === PiiLoggingLevel.OFF}
              onChange={() => {
                setProperty(s => {
                  s.piiLoggingLevel = PiiLoggingLevel.OFF;
                });
              }}
            />
            No
          </Label>
          <Label data-testid="log-payload-on-failures-label" className="alert-bar__title">
            <Radio
              data-testid="log-payload-on-failures"
              value={PiiLoggingLevel.FAILURES_ONLY}
              type="radio"
              name="piiLoggingLevel"
              defaultChecked={step.piiLoggingLevel === PiiLoggingLevel.FAILURES_ONLY}
              onChange={() =>
                setProperty(s => {
                  s.piiLoggingLevel = PiiLoggingLevel.FAILURES_ONLY;
                })
              }
            />
            Failures only
          </Label>
          <Label data-testid="log-payload-on-errors-label" className="alert-bar__title">
            <Radio
              data-testid="log-payload-on-errors"
              type="radio"
              value={PiiLoggingLevel.EXCEPT_ON_SUCCESS}
              name="piiLoggingLevel"
              defaultChecked={step.piiLoggingLevel === PiiLoggingLevel.EXCEPT_ON_SUCCESS}
              onChange={() =>
                setProperty(s => {
                  s.piiLoggingLevel = PiiLoggingLevel.EXCEPT_ON_SUCCESS;
                })
              }
            />
            All not successful
          </Label>
        </div>
      </FormGroup>

      {isAuthorisedToUpdatePiiLogging ? (
        <Button data-testid="validate-and-save-details-btn" onClick={validateAndSaveDetails}>
          Save details
        </Button>
      ) : null}
      <ErrorText data-testid="error-text">{error}</ErrorText>
      <Dialog
        data-testid={TestIds.CONFIRM_SAVE_DIALOG}
        isVisible={confirmationDialogVisible}
        type={AlertBarType.Warn}
        icon={'alertTriangle'}
        title={'Using templated URLs'}
        text={'Usage of dynamic URLs through templates can have security implications. Have you considered this?'}
        onDismissed={() => setConfirmationDialogVisible(false)}
        dismissButtonText={'Cancel'}
      >
        <Button
          data-testid={TestIds.CONFIRM_SAVE_BUTTON}
          kind={ButtonKind.Destructive}
          onClick={saveDetails}
          className={'button-margin'}
        >
          I understand, proceed
        </Button>
      </Dialog>
    </>
  );
};

export default StepForm;

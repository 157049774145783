import './Dataset.css';
import { useCallback, useMemo, useState } from 'react';
import { Dataset } from '../../../model/Dataset';
import { useDatasets } from '../../../hooks/useDatasets';
import DatasetForm from '../datasetForm/DatasetForm';
import { useNavigate } from 'react-router-dom';
import { Identity } from '../../../model/Identity';
import { isAuthorised } from '../../../services/Authorisation';
import { toCreate } from '../../../model/RequiredClaims';
import { ToastPosition, ToastType, toast } from '@gbg/gbgcomponentlibrary_react';

const newDataset = {
  id: null,
  version: 1,
  description: '',
  country: '',
  provider: '',
  pci_handler: false,
  status: 'DRAFT',
};

const AddDataset = ({ identity }: { identity: Identity }) => {
  const [dataset, setDataset] = useState<Dataset>(newDataset);
  const { insertDataset } = useDatasets();
  const navigate = useNavigate();

  const isAuthorisedToCreate = useMemo(() => {
    const { authorised } = isAuthorised(identity, toCreate);
    return authorised;
  }, [identity]);

  const persistDetails = useCallback(
    async (id: number, version: number, name: string, country: string, provider: string, pci_handler: boolean) => {
      const errors = await insertDataset(id, version, name, country, provider, pci_handler);
      if (!errors) {
        toast({
          title: '',
          content: `${name} has been saved`,
          position: ToastPosition.Top,
          type: ToastType.Success,
          duration: 3000,
          dismissable: false,
        });
        navigate(`/datasets/${id}/versions/${version}`);
      }
      return errors;
    },
    [insertDataset, navigate],
  );

  return (
    <>
      <div>
        <div>
          <h1 data-testid="add-dataset-header">New Dataset</h1>
        </div>
      </div>
      <DatasetForm
        dataset={dataset}
        isAuthorised={isAuthorisedToCreate}
        setDataset={setDataset}
        persist={persistDetails}
        visibleFields={['id']}
      />
    </>
  );
};

export default AddDataset;

import axios, { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useAxiosClient } from './useAxiosClient';
import { Error as APIError } from '../model/Error';
import { ValidationSchema } from '../model/Schema';

interface UseValidationSchema {
  getValidationSchema: (datasetId: number, datasetVersion: number) => Promise<ValidationSchema | APIError[]>;
  updateValidationSchema: (
    datasetId: number,
    datasetVersion: number,
    schema: string,
  ) => Promise<undefined | APIError[]>;
}

export const useValidationSchema = (): UseValidationSchema => {
  const create = useAxiosClient();

  const getValidationSchema = useCallback(
    async (datasetId: number, datasetVersion: number): Promise<ValidationSchema | APIError[]> => {
      const client = create();
      try {
        const response: AxiosResponse<ValidationSchema> = await client.get<ValidationSchema>(
          `/datasets/${datasetId}/versions/${datasetVersion}/validationschema`,
        );
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  const updateValidationSchema = useCallback(
    async (datasetId: number, datasetVersion: number, schema: string): Promise<undefined | APIError[]> => {
      const client = create();
      try {
        await client.put<ValidationSchema>(`/datasets/${datasetId}/versions/${datasetVersion}/validationschema`, {
          schema: schema,
        });
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  return { getValidationSchema, updateValidationSchema };
};
export default useValidationSchema;

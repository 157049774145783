import axios, { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useAxiosClient } from './useAxiosClient';
import { Error as APIError } from '../model/Error';
import { CheckResults, Status } from '../model/Dataset';

interface UseWorkflow {
  setWorkflowStatus: (
    datasetId: number,
    datasetVersion: number,
    status: Status,
  ) => Promise<undefined | CheckResults | APIError[]>;
  promote: (datasetId: number, datasetVersion: number) => Promise<undefined | APIError[]>;
}

export const useWorkflow = (): UseWorkflow => {
  const create = useAxiosClient();

  const setWorkflowStatus = useCallback(
    async (
      datasetId: number,
      datasetVersion: number,
      status: Status,
    ): Promise<undefined | CheckResults | APIError[]> => {
      const client = create();

      try {
        const response: AxiosResponse<undefined | CheckResults> = await client.post<undefined | CheckResults>(
          `/set-workflow-status`,
          {
            id: datasetId,
            version: datasetVersion,
            status: status,
          },
        );
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  const promote = useCallback(
    async (datasetId: number, datasetVersion: number): Promise<undefined | APIError[]> => {
      const client = create();

      try {
        await client.post<undefined>(`/promote`, {
          id: datasetId,
          version: datasetVersion,
        });
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          return error.response?.data.errors;
        }
        return [
          { problem: 'Unexpected error calling API', code: 99999, action: 'Contact Team Rocket', location: 'API Call' },
        ];
      }
    },
    [create],
  );

  return { setWorkflowStatus, promote };
};
export default useWorkflow;
